import { IblockDataType } from "../blocks"
import imageType  from './common/imageType';

export default {
    type: "awards",
    formProps: {
        schema: {
            type: "object",
            properties: {
                items: {
                    type: "array",
                    title: "Награды",
                    items: {
                        type: "object",                        
                        properties: {
                          id: {
                            type: "string",
                          },
                          header: {
                            type: "string",
                            title: "Заголовок"
                          },
                          photoFile: imageType,
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            items: {
                items: {
                    id: {
                        'ui:widget': 'hidden'
                    }, 
                    photoFile: {
                        "ui:field": "imageField"
                    }
                }            
            }
        }
    }
} as IblockDataType;