import React from "react";
import { useTranslate } from "@pankod/refine-core";
import {
    Form,
    Input,
    Radio,
    Select,
    useSelect, 
  } from "@pankod/refine-antd";

import ImageUpload from 'components/form/ImageUpload';

import { types } from './types' 

const NewsForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate();
    const { selectProps: selectProjectProps } = useSelect({
      resource: "projects",
      optionLabel: "name",
      optionValue: "id",               
      sort: [
          {
              field: "name",
              order: "asc",
          },
      ],
    });

    return(
        <Form {...fprops} layout="vertical">
        <Form.Item
          label={t("news.fields.type")}
          name="type"
          initialValue={"news"}
          rules={[{
              required: true,
            }]}>
          <Radio.Group name="type" buttonStyle="solid">
            {types.map((type, i) => (
              <Radio.Button key={i} value={type.value}>{t(type.label)}</Radio.Button>
            ))}          
          </Radio.Group>
        </Form.Item>
        
        <Form.Item
          label={t("news.fields.header")}
          name="header"
          rules={[{
              required: true,
            }]}>
          <Input />
        </Form.Item> 

        <Form.Item
          label={`${t("news.fields.path")} (если пустой, генерируется автоматически)`}
          name="path">
          <Input />          
        </Form.Item>
        

        <Form.Item label={t("news.fields.image")}>
          <ImageUpload fieldName="imageFile" model="App\Entity\News"/>            
        </Form.Item> 

        <Form.Item
          label={t("news.fields.date")}
          name="date">
          <Input type="date"/>
        </Form.Item>       

        <Form.Item
          label={t("news.fields.projects")}
          name="projects">       
            <Select {...selectProjectProps} mode="multiple"  />
        </Form.Item> 

        <Form.Item
            label={t("news.fields.description")}
            name="description"
        >
            <Input.TextArea />
        </Form.Item>    
            
      </Form>
    )
}

export default NewsForm;