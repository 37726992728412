import { IblockDataType } from "../blocks";
import linkType  from './common/linkType';
import imageType  from './common/imageType';

export default {
    type: "bigImageText",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок блока"
                },
                topHeader: {
                    type: "string",
                    title: "Заголовок над блоком"
                },
                text: {
                    type: "string",
                    title: "Описание"
                },
                link: linkType("Ссылка"),
                photoFile: imageType,                                       
            }
        },
        uiSchema: {
            text: {
                "ui:widget": "textarea"
            },
            link: {
                "ui:field": "linkField"
            },
            photoFile: {
                "ui:field": "imageField",
                "ui:upload-model": "App\\Entity\\ContentBlock\\BigImageText"
            },        
        }
    }
} as IblockDataType;
