import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { INews } from "interfaces";
import useTitle from '../../utils/useTitle'
import Form from "./form";

export const DocsCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('docs.createTitle')}`)

  const { formProps, saveButtonProps } = useForm<INews>();

  return (
    <Create saveButtonProps={saveButtonProps} title={t('docs.createTitle')}>
      <Form fprops={formProps} /> 
    </Create>
  );
};
