import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd";
import { IOffice } from "interfaces";
import useTitle from '../../utils/useTitle'

export const OfficeList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('office.office')}`)

  const { tableProps, sorter } = useTable<IOffice>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });
  return (
    <List>
      <Table 
        {...tableProps} 
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="header"
          key="header"
          title={t("table.name")}  
          defaultSortOrder={getDefaultSortOrder("header", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="phone"
          key="phone"
          title={t("table.tel")}        
          defaultSortOrder={getDefaultSortOrder("phone", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title={t("table.email")}      
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
          sorter
        /> 

        <Table.Column<IOffice>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
