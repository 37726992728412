import { useEffect } from 'react';
import { useDelete, useTranslate } from "@pankod/refine-core";
import { Button, Popconfirm } from "@pankod/refine-antd";
import { DeleteOutlined } from '@ant-design/icons';

interface IBlockDeleteButtonProps {
    itemId: number,
    resource: string,
    onDeleteComplete?: () => void
}

export const BlockDeleteButton: React.FC<IBlockDeleteButtonProps> = ({ itemId, resource, onDeleteComplete }: IBlockDeleteButtonProps) => { 
    const t = useTranslate();
    const { mutate, isSuccess } = useDelete();    

    useEffect(() => {
        if(isSuccess) {
            onDeleteComplete && onDeleteComplete()
        }       
    }, [isSuccess]); 
    return (
        <Popconfirm
            title={t(`block.deleteConfirm`)}
            onConfirm={() => {mutate({ 
                id: itemId,
                resource: resource,
                successNotification: { message: t(`block.delete.success`), type: "success"},
                errorNotification: { message: t(`block.delete.error`), type: "error"},
            })}}
            onCancel={() => {console.log("onCancel")}}
            okText={t(`buttons.delete`)}
            cancelText={t(`buttons.cancel`)}
        >
            
            <Button danger type="text">
                <DeleteOutlined />                
                Удалить
            </Button>
        </Popconfirm>
    )
};