const linkType = (title: string) => ({
    type: ["object", "null"],
    title: title,
    properties: {
        type: {},
        header: {},
        url: {},
        page: {},
        news: {},
        newsList: {},
        popup: { type: "boolean" }                                  
    },
})

export default linkType;