import {
  useTranslate,  
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  useModalForm,
  Modal,
  TagField
} from "@pankod/refine-antd";
import { IPlan } from "interfaces";
import PlanForm from './form';

const RESOURSE = 'plans';

const ProjectPlansList: React.FC<any> = ({ projectId }: any) => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IPlan>({
    resource: RESOURSE,
    permanentFilter: [
      {
          field: "projectId",
          operator: "eq",
          value: projectId,
      },
   ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });


  // Create Modal
  const {
      modalProps: createModalProps,
      formProps: createFormProps,
      show: createModalShow
  } = useModalForm<IPlan>({
      resource: RESOURSE,
      action: "create",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });

  // Edit Modal
  const {
      modalProps: editModalProps,
      formProps: editFormProps,
      show: editModalShow,
      id: recordId
  } = useModalForm<IPlan>({
      resource: RESOURSE,      
      action: "edit",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });
      
  return (
    <>
    <List 
      title={t("plan.list")}
      createButtonProps={{
        resource: "lands",
        onClick: () => {
            createModalShow();
        },
      }}         
    >
      <Table<IPlan> 
        {...tableProps}      
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="type"
          key="type"
          title={t("plan.fields.type")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("type", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t("plan.fields.status")}
          render={(value) => <TagField color={(value.value === 'done') ? 'green': ((value.value === 'error') ? 'red' : 'geekblue')} value={value.name} />}
       
        />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("plan.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        /> 

        <Table.Column
          dataIndex="path"
          key="path"
          title={t("plan.fields.path")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("path", sorter)}
          sorter
        />    
 
        <Table.Column<IPlan>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton 
                hideText 
                size="small"
                onClick={() => editModalShow(`${record.id}`)}
              />
              <DeleteButton hideText size="small" resourceName={RESOURSE} recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>

    <Modal {...createModalProps} title={t('plan.createTitle')} >
      <PlanForm fprops={createFormProps} projectId={projectId} />
    </Modal>
    <Modal {...editModalProps} title={t('plan.editTitle')} >
      <PlanForm fprops={editFormProps} projectId={projectId} recordId={recordId} />
    </Modal>
    </>
  );
};

export default ProjectPlansList;
