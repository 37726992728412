import List from "./list";
import Create from "./create";
import Edit from "./edit";
import { HomeOutlined } from '@ant-design/icons';

const Crud = {
    name: "projects",
    list: List,
    create: Create,
    edit: Edit,
    icon: <HomeOutlined />,
    options: {
        label: "Проекты"        
    }
}

export default Crud;
