import { OfficeList } from "./list";
import { OfficeCreate } from "./create";
import { OfficeEdit } from "./edit";
import { ClusterOutlined } from '@ant-design/icons';

const OfficeCrud = {
    name: "office",
    list:  OfficeList,
    create: OfficeCreate,
    edit:  OfficeEdit,
    icon: <ClusterOutlined />,
    options: {
        label: "Офисы",
        hasParent: true
    }
}

export default OfficeCrud;
