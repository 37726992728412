import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';

export default {
    type: "villageCards",
    formProps: {
        schema: {
            type: "object",
            properties: {
                items: {
                    type: "array",
                    title: "Карточки",
                    items: {
                        type: "object",                        
                        properties: {
                            display: {
                                title: "Типоразмер",
                                type: "string",
                                default: "1",
                                enum: ["1", "2", "3"]
                            },
                            header: {
                                title: "Заголовок", 
                                type: ["string", "null"]
                            },
                            text: {
                                title: "Описание",  
                                type: ["string", "null"]
                            },
                            photoFile: imageType,
                            link: linkType("Кнопка"),
                            linkOth: linkType("Ссылка")
                        }
                    }
                }                         
            }
        },
        uiSchema: {          
            items: {
                items: {                    
                    photoFile: {
                        "ui:field": "imageField"
                    },
                    link: {
                        "ui:field": "linkField"
                    },
                    linkOth: {
                        "ui:field": "linkField"
                    }                 
                }
            }
        }
    }
} as IblockDataType;