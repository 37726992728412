import React from "react";
import { useTranslate } from "@pankod/refine-core";
import {
    Form,
    Input,  
    Select,
    useSelect,    
  } from "@pankod/refine-antd";

import FileUpload from 'components/form/FileUpload'

const NewsForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate();
    const { selectProps: selectProjectProps } = useSelect({
      resource: "projects",
      optionLabel: "name",
      optionValue: "id",               
      sort: [
          {
              field: "name",
              order: "asc",
          },
      ],
    });;

    return(
      <Form {...fprops} layout="vertical">     
        <Form.Item
          label={t("docs.fields.header")}
          name="header"
          rules={[{
              required: true,
            }]}>
          <Input />
        </Form.Item>

        <Form.Item label={t("docs.fields.file")}>
          <FileUpload fieldName="uploadedFile" model="App\Entity\Document"/>            
        </Form.Item>    

        <Form.Item
          label={t("docs.fields.manager")}
          name="manager"
          rules={[{
              required: true,
            }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={t("docs.fields.date")}
          name="date">
          <Input type="date"/>
        </Form.Item>       

        <Form.Item
          label={t("docs.fields.project")}
          rules={[{
            required: true,
          }]}
          name="project">       
            <Select {...selectProjectProps} />
        </Form.Item>           
      </Form>
    )
}

export default NewsForm;