import { useState, useEffect } from 'react';
import { Drawer, Select, Form, Input, Divider, Popover } from "@pankod/refine-antd";
import { useTranslate, useUpdate, useCreate } from "@pankod/refine-core";
import { IblockDataType, blocks, getBlockDataTypeProps } from "./blocks";
import SchemaForm from 'components/schemaForm';

const DrawerForm: React.FC<any> = ({ block, visible, onClose, afterVisibleChange, mode, resourse, itemId }: any) => {
    const [blockFormState, setBlockFormState] = useState<any>(null);
    const [dataState, setDataState] = useState<any>(null);
    const [formErrors, setformErrors] = useState<any>(undefined); 

    const t = useTranslate();    
    const { 
        mutate: updateBlock,         
        isError: isUpdateBlockError, 
        error: updateBlockError,
        data: updateBlockData,
        reset: updateBlockReset  
    } = useUpdate<any>();

    const { 
        mutate: createBlock, 
        isError: isCreateBlockError, 
        error: createBlockError,
        data: createBlockData,
        reset: createBlockReset 
    } = useCreate<any>();

    const selectOptions = () => blocks.map((b: IblockDataType)=>({label: t(`block.types.${b.type}`), value: b.type}));
    
    const typeChange = (type: any) => {     
        setBlockFormState({...blockFormState, type });
    }

    useEffect(() => {
        setBlockFormState({ header: block?.header, type: block?.content?.type, id: block?.id });              
    }, [block]);

    useEffect(() => {
        if(visible) {          
            setDataState(block?.content);
        }
    }, [block?.content, visible]);    

    useEffect(() => {
        if(updateBlockData?.data === true) { 
            updateBlockReset()     
            onClose()
        }       
    }, [updateBlockData, onClose, updateBlockReset]);

    useEffect(() => {
        if(createBlockData?.data === true) { 
            createBlockReset()     
            onClose()
        }       
    }, [createBlockData, onClose, createBlockReset]);
    
    useEffect(() => { 
        if(isUpdateBlockError)  {
            const errors = updateBlockError?.response?.data?.errors[blockFormState?.type] || undefined; 
            setformErrors(errors); 
        }

        if(isCreateBlockError)  {
            const errors = createBlockError?.response?.data?.errors[blockFormState?.type] || undefined; 
            setformErrors(errors);  
        }

    }, [isUpdateBlockError, isCreateBlockError, createBlockError, updateBlockError]);
    
    const formOnSubmit = (data: any) => {
        setDataState(data?.formData);

        if(mode==="edit") {
            updateBlock({
                id: blockFormState.id,
                resource: resourse,
                successNotification: { message: t(`block.update.success`), type: "success"},
                errorNotification: { message: t(`block.update.error`), type: "error"},
                values: {
                    parent: itemId,
                    main: blockFormState,
                    block: data?.formData                    
                }                
            });
        } else {
            createBlock({
                resource: resourse,
                successNotification: { message: t(`block.create.success`), type: "success"},
                errorNotification: { message: t(`block.create.error`), type: "error"},
                values: {
                    parent: itemId,
                    main: blockFormState,
                    block: data?.formData                    
                }
            });
        }
    }

    const formOnChange = (data: any) => {
        setformErrors(undefined);
        setDataState(data?.formData);        
    }

    return (
        <Drawer title={t(`block.form.${mode}`)} placement="right" onClose={onClose} afterVisibleChange={afterVisibleChange} visible={visible}>
            <Form layout="vertical">
                <Form.Item label="Тип блока">
                    <Select
                        disabled={(mode==="edit")}
                        onChange={typeChange}                        
                        value={blockFormState?.type}                 
                        placeholder="Выберите тип блока" 
                        style={{width: "100%"}} 
                    >
                        { selectOptions().map((item, i) => (
                            <Select.Option key={i} value={item?.value}>
                                <Popover 
                                    zIndex={999999} 
                                    content={<img alt="img" style={{maxWidth: "30vw" }} src={`/images/blocks/${item?.value}.png`}/>} placement="leftTop" >
                                    <div>{`${item?.label} (${item?.value})`}</div>
                                </Popover>
                            </Select.Option>
                            )
                        ) }
                    </Select>
                </Form.Item>
                <Form.Item label="Название блока" >
                    <Input 
                        type="text" 
                        value={blockFormState?.header} 
                        onChange={(e)=> setBlockFormState({...blockFormState, header: e.target.value})} />
                </Form.Item>
            </Form>
            <Divider /> 
            <SchemaForm 
                disabled={!(blockFormState?.type)} 
                {...getBlockDataTypeProps(blockFormState?.type)} 
                formData={dataState} 
                onSubmit={formOnSubmit}
                onChange={formOnChange}
                extraErrors={formErrors}
                noValidate={true}
            />                  
        </Drawer>
    );
}

export default DrawerForm;