
import { Input } from "@pankod/refine-antd";

export default (props: any) => {

    const widget = props?.uiSchema["ui:widget"] || null; 
    
    const handleChange = (e: any) => {
        props?.onChange(e?.target?.value);
    }     

    return (<Input.TextArea
        rows={(widget==='textarea')?7:2}
        value={props?.value} 
        onChange={handleChange}
        />);
};