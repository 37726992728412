import { useTranslate } from "@pankod/refine-core";
import {
  Form,
  Select,
  useSelect
} from "@pankod/refine-antd";

import FileUpload from 'components/form/FileUpload';

const ImportForm: React.FC<any> = ({ fprops, projectId }: any) => {

    const t = useTranslate(); 

    const { selectProps: selectProjectProps } = useSelect({
        resource: "projects",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });  
    

    return(
        <Form {...fprops} layout="vertical">            
            <Form.Item
                label={t("import.fields.project")}
                name="project" 
                initialValue={parseInt(projectId)}
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}                     
            
                >       
                <Select {...selectProjectProps} disabled={true} />
            </Form.Item>   

            <Form.Item label={t("import.fields.file")}>
                <FileUpload fieldName="uploadedFile" model="App\Entity\Import"/>        
            </Form.Item>                          
        </Form>
    )
}

export default ImportForm;