import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  useForm
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import useTitle from '../../utils/useTitle'
import Form from "./form";


export const MetaTagCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('social.fields.createsocial')}`)

  const { formProps, saveButtonProps } = useForm<any>();

  return (
    <Create saveButtonProps={saveButtonProps} title={t("social.fields.createsocial")}>
      <Form fprops={formProps} /> 
    </Create>
  );
};
