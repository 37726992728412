import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';

export default {
    type: "imageText",
    formProps: {
        schema: {
            type: "object",
            properties: {
                text: {
                    type: ["string", "null"],
                    title: "Текст"
                },
                photoFile: imageType,
                link: linkType("Ссылка"),                                      
            }
        },
        uiSchema: {
            text: {
                "ui:widget": "textarea"
            },
            link: {
                "ui:field": "linkField"
            },
            photoFile: {
                "ui:field": "imageField",
                "ui:upload-model": "App\\Entity\\ContentBlock\\ImageText"
            },        
        }
    }
} as IblockDataType;
