
import LinkSelector from "components/form/LinkSelector";

export default (props: any) => {  
    return (
        <>
        <label style={{fontWeight: 500 }}>{props?.schema?.title}</label>        
        <LinkSelector value={props?.formData} onChange={props?.onChange} /> 
        </>      
    );
};