import { useTranslate } from "@pankod/refine-core";
import { Form, Input } from "@pankod/refine-antd";
import ImageUpload from 'components/form/ImageUpload';

const ProjectForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate(); 

    return(
        <Form {...fprops} layout="vertical">
          <Form.Item
            label={t("social.fields.url")}
            name="url"
            >
            <Input />
            </Form.Item>

            <Form.Item label={t("social.fields.image")}>
                <ImageUpload fieldName="imageFile" model="App\Entity\Social"/>            
            </Form.Item>        
                        
      </Form>
    )
}

export default ProjectForm;