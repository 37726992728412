
import { MapPointEdit } from 'components/form/MapPointEdit';
import ProjectPlaceMark from 'components/form/MapPointEdit/projectPlacemark';
import { useList } from "@pankod/refine-core";
import { IMapProject } from "interfaces";

export default (props: any) => {
    const { data } = useList<IMapProject>({
        resource: "projects"
    });

    const points = data?.data?.map((project: IMapProject, i: number) => {
        return <ProjectPlaceMark key={i} {...project} />
    })

    return (
        <MapPointEdit onChange={props?.onChange} value={props?.formData} points={points}/>
    );
};