export const statuses = [
    {
        label: "Свободен",
        value: "free",
    },
    {
        label: "Забронирован",
        value: "reserved",
    },
    {
        label: "Продан",
        value: "sold"
    },
    {
        label: "Снят с продажи",
        value: "salestop"
    }
];