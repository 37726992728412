import { useTranslate } from "@pankod/refine-core";
import {
  Form,
  Input,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { Blocks } from 'components/blocks';
import './style.scss';


const PageForm: React.FC<any> = ({ fprops, projectId, pageId }: any) => {
    const t = useTranslate();
     
    const { selectProps: selectProjectProps } = useSelect({
        resource: "projects",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });    


    return(
        <>        
        <Form {...fprops} layout="vertical">            
            <Form.Item
            label={t("pages.fields.project")}
            name="project" 
            initialValue={parseInt(projectId)}
            rules={[
                {
                required: true,
                },
            ]}                     
         
            >       
            <Select {...selectProjectProps} />
            </Form.Item>
            <Form.Item
            label={t("pages.fields.name")}
            name="name"            
            rules={[
                {
                required: true,
                },
            ]}
            >
            <Input />
            </Form.Item>       
            <Form.Item
            label={t("pages.fields.path")}
            name="path"
            rules={[
                {
                required: true,
                },
            ]}
            >
            <Input />
            </Form.Item>
                           
        </Form>
        {pageId ? <Blocks resourse={"pages/blocks"} itemId={`${pageId}`} containerTitle={`Блоки страницы`}/>:""}
        </>
    )
}

export default PageForm;