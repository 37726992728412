import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';

export default {
    type: "history",
    formProps: {
        schema: {
            type: "object",
            properties: {
                items: {
                    type: "array",
                    title: "События",
                    items: {
                        type: "object",                        
                        properties: {
                          header: {
                            type: "string",
                            title: "Заголовок"
                          },
                          description: {
                            type: "string",
                            title: "Дата"
                          },
                          text: {
                            type: "string",
                            title: "Текст"
                          },
                          photoFile: imageType,
                          link: linkType("Ссылка"),
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            items: {
                items: {
                    photoFile: {
                        "ui:field": "imageField"
                    },
                    link: {
                        "ui:field": "linkField"
                    },
                }            
            }
        }
    }
} as IblockDataType;