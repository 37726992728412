import { useTranslate } from "@pankod/refine-core";
import {
  Form,
  Input,
  Select,
  useSelect,
} from "@pankod/refine-antd";

import { statuses } from "./statuses";
import ImagesUploadDND from 'components/form/ImagesUploadDND';

const LandForm: React.FC<any> = ({ fprops, projectId }: any) => {
    const t = useTranslate(); 
    const { selectProps: selectProjectProps } = useSelect({
        resource: "projects",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });

    return(
        <Form {...fprops} layout="vertical">            
            <Form.Item
            label={t("lands.fields.project")}
            name="project" 
            initialValue={parseInt(projectId)}
            rules={[
                {
                required: true,
                message: `${t("messageError")}`
                },
            ]}                     
         
            >       
                <Select {...selectProjectProps} disabled={true} />
            </Form.Item>
            <Form.Item
            label={t("lands.fields.number")}
            name="number"               
            rules={[
                {
                required: true,
                message: `${t("messageError")}`
                },
            ]}
            >
            <Input />
            </Form.Item> 
            <Form.Item
            label={t("lands.fields.status")}
            name="status"
            initialValue={"free"}           
            rules={[
                {
                required: true,
                message: `${t("messageError")}`
                },
            ]}
            >       
            <Select options={statuses} />
            </Form.Item>
            <Form.Item
            label={t("lands.fields.area")}
            name="area"
            rules={[
                {
                required: true,
                message: `${t("messageError")}`
                },
            ]}
            >
            <Input />
            </Form.Item>

            <Form.Item
                label={t("lands.fields.price")}
                name="price"
                >
                <Input />
            </Form.Item>

            <Form.Item
                label={t("lands.fields.discountPrice")}
                name="discountPrice"
                >
                <Input />
            </Form.Item>

            <Form.Item
                label={t("houses.fields.images")}
                name="photos">
                <ImagesUploadDND maxCount={10} fileFieldName="photoFile" model="App\Entity\LandPhoto"/>
            </Form.Item> 
                  
        </Form>
    )
}

export default LandForm;