import { Link } from "react-router-dom";



const Logo = ({collapsed} : {collapsed:boolean}) => {
    return (
        <Link to='/' style={{maxHeight:"100px", height:"100px", paddingTop:"15px", display:"inline-block"}}>
            { !collapsed &&  <svg width="201" height="70" viewBox="0 0 1339 1086" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M873.944 573.527C962.127 573.527 1033.61 502.04 1033.61 413.857C1033.61 325.674 962.127 254.188 873.944 254.188C785.761 254.188 714.274 325.674 714.274 413.857C714.274 502.04 785.761 573.527 873.944 573.527Z" fill="#E91E63" />
                <path d="M207.73 587.291C292.887 344.687 440.115 212.363 649.412 190.316C858.71 168.27 1019.37 268.212 1131.4 490.141C1009.91 655.724 875.528 746.227 728.253 761.65C580.979 777.073 407.471 718.953 207.73 587.291Z" stroke="white" strokeWidth="39.0304" strokeLinecap="round" />
                <path d="M1092.16 151.352L993.232 293.919" stroke="white" strokeWidth="39.0304" strokeLinecap="round" />
                <path d="M828.454 20L769.766 188.185" stroke="white" strokeWidth="39.0304" strokeLinecap="round" />
                <path d="M402.171 72.3789L494.567 224.739" stroke="white" strokeWidth="39.0304" strokeLinecap="round" />
                <path d="M174.944 254.188L299.344 372.485" stroke="white" strokeWidth="39.0304" strokeLinecap="round" />
                <path d="M848.172 623.01C930.922 614.312 990.953 540.18 982.256 457.43C973.558 374.68 899.426 314.648 816.676 323.346C733.926 332.043 673.894 406.176 682.592 488.926C691.289 571.676 765.422 631.707 848.172 623.01Z" stroke="white" strokeWidth="39.0304" strokeLinecap="round" />
                <path d="M97.7311 691.692C67.5003 726.133 36.5362 759.865 4.83881 792.887C-10.3476 808.712 13.7094 832.84 28.8957 817.015C60.5931 783.898 91.5572 750.166 121.788 715.819C135.981 699.427 112.208 675.228 97.7311 691.692Z" fill="white" />
                <path d="M102.414 716.178C125.62 731.364 149.038 745.557 172.101 760.672C190.552 772.594 207.584 743.144 189.275 731.293C166.069 716.391 142.793 701.701 119.588 686.798C101.066 674.876 84.1056 704.398 102.414 716.178V716.178Z" fill="white" />
                <path d="M56.7848 775.644C74.5968 787.069 92.2669 798.353 110.576 809.281C128.885 820.21 146.555 791.185 127.749 779.902C108.944 768.619 91.7702 757.619 73.9581 746.265C56.1461 734.911 38.476 763.864 56.7848 775.644Z" fill="white" />
                <path d="M8.74159 820.711C35.4242 840.013 62.6508 858.605 90.4215 876.488C108.872 888.34 125.904 858.889 107.595 847.109C79.7769 829.297 52.6685 810.634 25.915 791.26C8.10292 778.416 -8.92853 807.937 8.74159 820.711Z" fill="white" />
                <path d="M370.291 844.369C346.636 883.636 322.153 922.335 296.843 960.467C284.708 978.776 314.158 995.949 326.293 977.64C351.556 939.462 376.015 900.762 399.67 861.543C411.024 842.737 381.574 825.564 370.291 844.369Z" fill="white" />
                <path d="M381.289 866.939C406.694 877.513 432.313 887.802 457.718 898.376C477.943 906.75 486.813 873.823 466.801 865.52C441.325 854.946 415.778 844.656 390.302 834.082C370.077 825.709 361.277 858.636 381.289 866.939V866.939Z" fill="white" />
                <path d="M343.962 931.949C366.245 941.246 387.889 951.89 410.385 960.335C430.822 968.283 439.693 935.355 419.468 927.478C396.972 918.75 375.257 908.105 352.974 899.093C348.628 897.913 343.991 898.497 340.071 900.715C336.152 902.934 333.266 906.61 332.04 910.944C330.853 915.346 331.436 920.039 333.664 924.017C335.892 927.996 339.588 930.944 343.962 932.233V931.949Z" fill="white" />
                <path d="M303.514 984.661C334.667 999.635 366.033 1013.69 397.896 1026.81C418.121 1035.19 426.992 1002.26 406.98 993.958C377.813 981.965 349.144 968.907 320.758 955.282C301.101 945.844 283.785 975.223 303.514 984.661V984.661Z" fill="white" />
                <path d="M221.394 780.031C221.394 811.918 221.229 843.805 220.898 875.691C220.898 897.619 254.677 897.619 254.961 875.691C255.292 843.852 255.457 811.965 255.457 780.031C255.457 758.103 221.394 758.032 221.394 780.031Z" fill="white" />
                <path d="M307.049 808.273C263.761 841.839 221.324 877.108 181.584 914.719C165.688 929.835 189.816 953.892 205.712 938.847C245.693 901.278 287.544 865.751 331.106 832.401C348.421 818.988 324.009 795.002 307.049 808.273V808.273Z" fill="white" />
                <path d="M557.891 908.017C549.943 956.769 542.421 1005.59 534.473 1054.35C530.925 1075.63 563.71 1085 567.258 1063.36C575.277 1014.68 582.729 965.853 590.748 917.1C594.296 895.811 561.439 886.444 557.891 908.017V908.017Z" fill="white" />
                <path d="M521.06 922.277C555.407 926.535 589.754 930.864 624.1 935.973C645.39 939.167 654.757 906.381 633.113 903.117C595.857 897.582 558.458 892.898 521.06 888.214C499.345 885.518 499.771 919.581 521.06 922.277V922.277Z" fill="white" />
                <path d="M675.603 918.007C675.603 968.463 674.468 1018.85 674.397 1069.3C674.397 1091.23 708.46 1091.23 708.46 1069.3C708.46 1018.85 709.524 968.463 709.666 918.007C709.666 896.15 675.674 896.079 675.603 918.007Z" fill="white" />
                <path d="M697.175 934.47C712.859 930.85 757.779 927.373 753.166 953.701C749.618 974.281 721.374 980.951 703.491 981.377C681.634 981.874 681.563 1015.94 703.491 1015.44C741.67 1014.52 786.732 993.512 787.158 949.94C787.655 899.129 725.561 893.168 687.808 901.613C666.519 906.581 675.531 939.366 696.892 934.47H697.175Z" fill="white" />
                <path d="M724.142 998.905C741.812 1022.61 758.702 1046.88 776.088 1070.79C788.862 1088.32 818.667 1071.36 805.467 1053.62C788.081 1029.7 771.192 1005.43 753.521 981.732C740.606 964.345 710.943 981.306 724.142 998.905Z" fill="white" />
                <path d="M1072.89 966.71L978.87 836.19C966.877 819.229 938.562 835.48 950.484 852.654L1044.58 983.174C1056.57 1000.13 1084.89 983.883 1072.97 966.71H1072.89Z" fill="white" />
                <path d="M963.318 861.821C1022.79 880.744 1084.35 906.928 1144.86 922.966C1165.16 928.359 1173.82 896.922 1153.52 891.528C1093.15 875.585 1031.59 849.426 971.976 830.454C951.964 823.997 943.59 855.505 963.318 861.821Z" fill="white" />
                <path d="M1036.57 795.506C1070.49 835.743 1105.55 874.915 1138.12 916.145C1151.04 932.609 1173.6 909.049 1161.11 893.082C1128.61 851.781 1093.48 812.608 1059.63 772.443C1046.08 756.405 1023.09 779.539 1036.57 795.506Z" fill="white" />
                <path d="M1094.92 747.08C1132.81 780.15 1175.73 816.453 1213.69 849.381C1230.23 863.574 1254.43 839.659 1237.82 825.253C1199.78 792.326 1156.87 756.093 1119.05 722.952C1102.58 708.759 1078.46 732.603 1094.92 747.08Z" fill="white" />
                <path d="M934.758 1042.98C1014.54 999.024 937.447 834.613 857.93 878.035C779.69 920.919 845.562 1081.9 934.758 1042.98C953.175 1034.92 937.245 1007.96 918.962 1016.1C868.281 1038.08 829.833 925.826 873.793 905.056C917.752 884.286 964.266 991.16 918.962 1015.96C901.351 1025.37 917.214 1052.66 934.758 1042.98Z" fill="white" />
                <path d="M1240.58 601.679C1209.63 596.81 1183.69 618.753 1175.28 647.699C1163.41 689.45 1190.02 727.733 1224.24 749.076C1255.98 768.751 1297.6 770.352 1322.88 739.539C1332.97 727.646 1338.51 712.561 1338.52 696.966C1338.53 681.371 1333.02 666.277 1322.95 654.368C1309.61 638.962 1287.86 661.038 1300.8 676.511C1311.41 689.117 1308.47 708.458 1298.07 720.397C1282.26 738.471 1258.05 733.002 1240.04 721.864C1219.1 709.325 1199.43 686.516 1204.43 660.438C1206.7 648.632 1217.77 629.558 1232.24 631.892C1251.98 635.027 1260.45 604.813 1240.58 601.679Z" fill="white" />
            </svg>}
        </Link>
    )
}

export default Logo