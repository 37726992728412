import { useState, useCallback } from 'react'
import { useBetween } from 'use-between';
import { IBreadCrumb } from "interfaces";


export const useBreadCrumbs = () => {
    const [breadcrumbs, setBreadCrumbs] = useState<IBreadCrumb[]>([]);    

    const set = useCallback((breadcrumbs: IBreadCrumb[]) => {
        setBreadCrumbs(breadcrumbs);
    }, []);   

    return { breadcrumbs, set };   
}

export const useSharedBreadCrumbs = () => useBetween(useBreadCrumbs);

