import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  ListButton,
  RefreshButton,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import Form from "./form";
import useTitle from '../../utils/useTitle'

const DataEdit: React.FC<any> = ({ formProps, saveButtonProps, id}: any) => {  
  const t = useTranslate();
  useTitle(`${t('pages.editTitle')}`)   

  return (    
    <Edit 
      saveButtonProps={saveButtonProps} 
      title={t("pages.editTitle")} 
      pageHeaderProps={{
        extra: 
        <>
          <ListButton>{t("buttons.list")}</ListButton>
          <RefreshButton >{t("buttons.refresh")}</RefreshButton>
        </>
      }}> 
      <Form fprops={formProps} /> 
    </Edit>
  );
};

export default DataEdit;
