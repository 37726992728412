import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  TagField,
  DeleteButton,
} from "@pankod/refine-antd";
import { IMenu } from "interfaces";
import useTitle from 'utils/useTitle'
import { getBoolType } from 'utils/helpers';




export const MenuList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('menu.menu')}`)

  const { tableProps, sorter } = useTable<IMenu>({
    initialSorter: [
      {
        field: "sort",
        order: "asc",
      },
    ],
  });
  return (
    <List>
      <Table 
        {...tableProps} 
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="sort"
          key="sort"
          title={t("menu.fields.sort")}
          defaultSortOrder={getDefaultSortOrder("sort", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("menu.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="is_active"
          key="is_active"
          title={t("menu.fields.isActive")}
          render={(value) => <TagField color={(value === true) ? 'green' : 'red'} value={t(`menu.status.${getBoolType(value)}`)} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />

        <Table.Column<IMenu>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
