export const types = [
    {
        label: "news.fields.news",
        value: "news",
    },
    {
        label: "news.fields.campaign",
        value: "campaign",
    }
];

export const getType = (value: string): string => (types.find(e => (e.value===value))?.label as string)