import { Tabs } from "@pankod/refine-antd";
import { useState, useEffect, useContext} from "react";
import { useLocation } from "react-router-dom";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import type { History } from "history";

export const TabsWithUrl: React.FC<any> = ({ children, defaultActiveKey }: any) => {
    const [activeTab, setActiveTab] = useState<string>("1");
    const { search }  = useLocation();
    const navigator = useContext(NavigationContext).navigator as History;
    
    const onTabClick = (active: string) => {
        setActiveTab(active);
        const query = (active === defaultActiveKey) ? "":`?tab=${active}`;
        navigator.push({
          search: query
        });  
    }
  
    useEffect(() => {
      const params = new URLSearchParams(search);
      const currentTab = params.get("tab"); 
      if(currentTab) {
        const foundtab = children.find((e: any)=> (e.key === currentTab));
        const tabToSet = foundtab? currentTab: defaultActiveKey;
        setActiveTab(tabToSet);
      } else {
        setActiveTab(defaultActiveKey);
      }
    },[search, children, defaultActiveKey]);

    return (
        <Tabs defaultActiveKey={defaultActiveKey} activeKey={activeTab} type="card" onTabClick={onTabClick}>
            {children}
        </Tabs>
    );
}

export default TabsWithUrl;