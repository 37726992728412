import './imagesUploadDND.scss'
import { useTranslate } from "@pankod/refine-core";
import { Upload } from "@pankod/refine-antd";
import { API_HOST, UPLOAD_URL } from "utils/constants";
import { PlusOutlined } from '@ant-design/icons';
import { getAuthHeaders } from 'utils/authProvider';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';

interface IEyeFilesUpload {
    fileFieldName: string,
    model: string,
    maxCount: number,
    onChange?: Function,
    value?: any
}

const ImagesUploadDND: React.FC<IEyeFilesUpload> = ({value, model, onChange, maxCount, fileFieldName} ) => {

    const t = useTranslate();

    const getValueFromEvent = (event: any) => {  
        let result = event.fileList;
        if(event.file.status === "done") {
            result = result.filter((item: any) => {        
                return !!item?.url
            });
            const resp = event.file.response;        
            result.push({ ...resp, url: `${API_HOST}${resp.url}`, name: resp.originalName });         
        }

        onChange && onChange(result);
        return result;    
    }

    const UploadButton = () => (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{ t(`buttons.upload`) }</div>
        </div>
    );
    
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        padding: 1,
        width: '104px',
        height: '104px',
        objectFit: 'cover',
        margin: `0 0 5px 0`,
        borderRadius: '5px',
        ...draggableStyle
    });

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = reorder(
            value,
            result.source.index,
            result.destination.index
        );   
        
        onChange && onChange(items);
    }

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    return ( 
        <DragDropContext
            onDragEnd={handleOnDragEnd}>
            <Droppable
                direction="horizontal"
                mode="virtual"
                renderClone={(provided, snapshot, rubric) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}>
                        <img
                            style={{
                                width: '102px',
                                height: '102px',
                                padding: '8px',
                                margin: '1px 0 0 0',
                                opacity: '0.8',
                                objectFit: 'cover',
                                borderRadius: '6px',
                                border: "1px solid #67be23",
                            }}
                            alt={''}
                            src={value[rubric.source.index].url}/>
                    </div>
                  )}
                droppableId="dnd">
                {(provided) => (
                    <div {...provided.droppableProps}
                         style={{padding: 1, objectFit: 'cover'}}
                         ref={provided.innerRef}>            
                        <Upload
                            name={fileFieldName}
                            action={UPLOAD_URL}
                            headers={getAuthHeaders()}                               
                            listType="picture-card"
                            fileList={value}
                            data={(file: any) => {
                                return {
                                    filename: file.name,
                                    field: fileFieldName,
                                    model: model
                                }
                            }}
                            onChange={getValueFromEvent}                        
                            maxCount={maxCount}
                            itemRender={(originNode, file, currFileList) => {
                                return (
                                    <Draggable key={file?.uid}
                                               draggableId={`${file?.uid}`}
                                               index={currFileList.indexOf(file)}>
                                        {(provided, snapshot) => (
                                            <div key={file.uid}
                                                 ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}
                                                 style={getItemStyle(
                                                     snapshot.isDragging,
                                                     provided.draggableProps.style
                                                 )}>{originNode}</div>
                                        )}
                                    </Draggable>
                                )
                            }}>
                            {value?.length >= maxCount ? null : <UploadButton />}
                        </Upload>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default ImagesUploadDND;