import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';


export default {
    type: "bigGallery",
    formProps: {
        schema: {
            type: "object",
            properties: {
                header: {
                    type: "string",
                    title: "Заголовок"
                },     
             
                items: {
                    type: "array",
                    title: "Предложения",
                    items: {
                        type: "object",                        
                        properties: {
                            id: { type: "string" },
                            photoFile: imageType,
                            header: {
                                type: "string",
                                title: "Заголовок"
                            },
                            text: {
                                type: "string",
                                title: "Подпись"
                            },                            
                            link: linkType("Ссылка"),
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            items: {
                items: { 
                    id: {'ui:widget': 'hidden'},                    
                    photoFile: {
                        "ui:field": "imageField"
                    },
                    link: {
                        "ui:field": "linkField"
                    },
                    text: {
                        "ui:widget": "textarea"
                    }                 
                }
            }
        }
    }
} as IblockDataType;
