import {
  useTranslate,
  useExport  
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  useModalForm,
  Modal,
  ExportButton,
  CreateButton,
} from "@pankod/refine-antd";
import { ILand } from "interfaces";
import LandForm from './form';

const ProjectLandsList: React.FC<any> = ({ projectId }: any) => {
  const t = useTranslate();

  const { triggerExport, isLoading: exportLoading } = useExport<ILand>({
    resourceName: `lands`,
    pageSize: 99999,
    filters:[{
      field: "projectId",
      operator: "eq",
      value: projectId,
    }],   
    mapData: (item: any) => {        
        return {
            "ID": item.id,
            "Номер": item.number,
            "Площадь": item.area,
            "Стоимость": item.price,
            "Стоимость со скидкой": item.discountPrice,
            "Статус": item.status
        };
    },
  });

  const { tableProps, sorter } = useTable<ILand>({
    resource: 'lands',
    permanentFilter: [
      {
          field: "projectId",
          operator: "eq",
          value: projectId,
      },
   ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });


  // Create Modal
  const {
      modalProps: createModalProps,
      formProps: createFormProps,
      show: createModalShow
  } = useModalForm<ILand>({
      resource: `lands`,
      action: "create",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });

  // Edit Modal
  const {
      modalProps: editModalProps,
      formProps: editFormProps,
      show: editModalShow,
  } = useModalForm<ILand>({
      resource: "lands",      
      action: "edit",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });
      
  return (
    <>
    <List 
      title={t("lands.list")}
      pageHeaderProps={{
        extra: (
            <>
            <ExportButton
                onClick={triggerExport}
                loading={exportLoading}
            />
            <CreateButton resource={"lands"} onClick={() => createModalShow()}/>
            </>
        ),
      }}     
           
    >
      <Table<ILand> 
        {...tableProps}      
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="number"
          key="number"
          title={t("lands.fields.number")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("number", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t("lands.fields.status")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="statusChangeDate"
          key="statusChangeDate"
          title={t("lands.fields.statusChangeDate")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("statusChangeDate", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="area"
          key="area"
          title={t("lands.fields.area")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("area", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="price"
          key="price"
          title={t("lands.fields.price")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("price", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="discountPrice"
          key="discountPrice"
          title={t("lands.fields.discountPrice")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("discountPrice", sorter)}
          sorter
        />    

        <Table.Column<ILand>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton 
                hideText 
                size="small"
                onClick={() => editModalShow(`${record.id}`)}
              />
              <DeleteButton hideText size="small" resourceName={"lands"} recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>

    <Modal {...createModalProps}>
      <LandForm fprops={createFormProps} projectId={projectId} />
    </Modal>
    <Modal {...editModalProps}>
      <LandForm fprops={editFormProps} projectId={projectId} />
    </Modal>
    </>
  );
};

export default ProjectLandsList;
