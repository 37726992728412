const mapType = (title: string) => ({
    type: "object",
    title: title,
    properties: {
        point: {
            type: "object",
            properties: {
                lat: {
                    type: "string"
                },
                lng: {
                    type: "string"
                },
            }
        },
        center: {
            type: "object",
            properties: {
                lat: {
                    type: "string"
                },
                lng: {
                    type: "string"
                },
            }
        },
        zoom: {
            type: "number"
        },
    }
})

export default mapType;