import { useTranslate } from "@pankod/refine-core";
import {
  Form,
  Input,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import ImagesUploadDND from 'components/form/ImagesUploadDND';
import { statuses } from "./statuses";

const LandForm: React.FC<any> = ({ fprops, projectId }: any) => {
    const t = useTranslate(); 
    
    const { selectProps: selectProjectProps } = useSelect({
        resource: "projects",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });


    const { selectProps: selectLandProps } = useSelect({
        resource: `lands?projectId=${projectId}`,
        optionLabel: "number",
        optionValue: "id",               
        sort: [
            {
                field: "number",
                order: "asc",
            },
        ],
    });    


    return(
        <Form {...fprops} layout="vertical">            
            <Form.Item
                label={t("houses.fields.project")}
                name="project" 
                initialValue={parseInt(projectId)}
                rules={[
                    {
                    required: true,
                    },
                ]}                     
            
                >       
                <Select {...selectProjectProps} disabled={true} />
            </Form.Item>

            <Form.Item
                label={t("houses.fields.land")}
                name="land"              
                rules={[
                    {
                    required: true,
                    },
                ]}                     
            
                >       
                <Select {...selectLandProps} />
            </Form.Item>

            <Form.Item
            label={t("houses.fields.name")}
            name="name"            
            rules={[
                {
                required: true,
                },
            ]}
            >
            <Input />
            </Form.Item> 
            <Form.Item
            label={t("houses.fields.status")}
            name="status"
            initialValue={"free"}           
            rules={[
                {
                required: true,
                },
            ]}
            >       
            <Select options={statuses} />
            </Form.Item>
            <Form.Item
            label={t("houses.fields.area")}
            name="area"
            rules={[
                {
                required: true,
                },
            ]}
            >
            <Input />
            </Form.Item>            
            
            <Form.Item
                label={t("houses.fields.price")}
                name="price"
                >
                <Input />
            </Form.Item>

            <Form.Item
                label={t("houses.fields.discountPrice")}
                name="discountPrice"
                >
                <Input />
            </Form.Item>

            

            <Form.Item
                label={t("houses.fields.text")}
                name="text"
                >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                label={t("houses.fields.images")}
                name="photos">
                <ImagesUploadDND maxCount={20} fileFieldName="photoFile" model="App\Entity\HousePhoto"/>
            </Form.Item> 
                  
        </Form>
    )
}

export default LandForm;
