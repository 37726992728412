import {useTranslate} from "@pankod/refine-core";
import {Form, Input} from "@pankod/refine-antd";
import ImageUpload from 'components/form/ImageUpload';

const BankForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate(); 

    return (
        <Form {...fprops} layout="vertical">   

            <Form.Item
                label={t("bank.fields.name")}
                name="name"
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item label={t("bank.fields.logo")}>
                <ImageUpload fieldName="logoFile" model="App\Entity\Bank"/>            
            </Form.Item>           
        </Form>
    )
}

export default BankForm;