import { MetaTagList } from "./list";
import { MetaTagCreate } from "./create";
import { MetaTagEdit } from "./edit";
import { TagOutlined } from '@ant-design/icons';

const MetaTagCrud = {
    name: "metatag",
    list:  MetaTagList,
    create: MetaTagCreate,
    edit:  MetaTagEdit,
    icon: <TagOutlined />,
    options: {
        label: "Метатеги",
    }
}

export default MetaTagCrud;
