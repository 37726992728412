import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';

 const Cards: IblockDataType = {
    type: "cards",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                items: {
                    type: "array",
                    title: "Плитки",
                    items: {
                        type: "object",                        
                        properties: {
                          id: { type: "string" },
                          header: {
                            type: "string",
                            title: "Заголовок"
                          },
                          text: {
                            type: ["string", "null"],
                            title: "Текст"
                          },
                          photoFile: imageType,
                          link: linkType("Ссылка"),
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            items: {
                items: {
                    id: {'ui:widget': 'hidden'},                    
                    link: {
                        "ui:field": "linkField"
                    },
                    photoFile: {
                        "ui:field": "imageField"
                    },
                }
            }
        }
    }
} as IblockDataType;

export default Cards;
