import {
  useTranslate,  
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  useModalForm,
  Modal,
  Image
} from "@pankod/refine-antd";
import ItemForm from './form';

const resourseName = 'communications'

const ProjectCommList: React.FC<any> = ({ projectId }: any) => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<any>({
    resource: resourseName,
    permanentFilter: [
      {
          field: "projectId",
          operator: "eq",
          value: projectId,
      },
   ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });


  // Create Modal
  const {
      modalProps: createModalProps,
      formProps: createFormProps,
      show: createModalShow
  } = useModalForm<any>({
      resource: resourseName,
      action: "create",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });

  // Edit Modal
  const {
      modalProps: editModalProps,
      formProps: editFormProps,
      show: editModalShow,
  } = useModalForm<any>({
      resource: resourseName,      
      action: "edit",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });
      
  return (
    <>
    <List 
      title={t("communications.list")}
      createButtonProps={{
        resource: resourseName,
        onClick: () => {
            createModalShow();
        },
      }}         
    >
      <Table<any> 
        {...tableProps}      
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />

      <Table.Column
          dataIndex="image"
          key="image"
          title={t("communications.fields.image")}
          render={(value) =>  (value && <Image preview={false} height={40} src={value} />)}   
        />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("communications.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />    

        <Table.Column<any>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton 
                hideText 
                size="small"
                onClick={() => editModalShow(`${record.id}`)}
              />
              <DeleteButton hideText size="small" resourceName={resourseName} recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>

    <Modal {...createModalProps} title={t('communications.createTitle')}>
      <ItemForm fprops={createFormProps} projectId={projectId} />
    </Modal>
    <Modal {...editModalProps} title={t('communications.editTitle')}>
      <ItemForm fprops={editFormProps} projectId={projectId} />
    </Modal>
    </>
  );
};

export default ProjectCommList;
