import { IResourceComponentsProps } from "@pankod/refine-core";
import { Tabs, useForm } from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Blocks } from 'components/blocks';
import { NewsEdit } from './edit';
import { INews } from 'interfaces';
import useTitle from 'utils/useTitle';
import { useEffect } from 'react';
import { useSharedBreadCrumbs } from 'utils/useBreadCrumbs';
import TabsWithUrl from 'components/tabs';

const { TabPane } = Tabs;

export const NewsEditTabs: React.FC<IResourceComponentsProps> = () => {
  
  useTitle('Редактировать новость');
  const { formProps, saveButtonProps, id } = useForm<INews>();
  const { breadcrumbs, set: setBreadCrumbs } = useSharedBreadCrumbs();  
    
  useEffect(() => {
    const newsName = formProps?.form?.getFieldValue('header');     
    if(newsName && id && breadcrumbs.length===0) {
      setBreadCrumbs([{ label: `Редактирование новости "${newsName}"`, path: `news/edit/${id}`}]);
    }       
  }, [formProps?.form, id, breadcrumbs.length, setBreadCrumbs]);

  return (
    <TabsWithUrl defaultActiveKey="main">
      <TabPane tab={'Основное'} key="main">
        <NewsEdit formProps={formProps} saveButtonProps={saveButtonProps} id={id} />
      </TabPane>
      <TabPane tab={'Блоки'} key="blocks">
        <Blocks resourse={"news/blocks"} itemId={`${id}`} containerTitle={`Блоки страницы новости`}/>                  
      </TabPane>    
    </TabsWithUrl>
  );
};
