
import { Upload } from "antd";
import { UPLOAD_URL, API_HOST} from "utils/constants";
import UploadButton from './uploadButton';
import { getAuthHeaders } from 'utils/authProvider';

const fieldName = 'photoFile';
const modelName = "App\\Entity\\ContentBlock\\CollectionItem";

const ImageField = (props: any) => {    
    
    const handleChange = (value: any) => {       

        const currentFileList = value?.fileList;       

        if(currentFileList?.length === 0) {
            props?.onChange({ delete: 1, token:"", filename:"", originalFilename:"" });
            return;
        }
 
        const file = currentFileList[0] || {};
        if(file?.status==="done" && file?.response?.token) {
            props?.onChange({ 
                token: file?.response?.token,
                filename: file?.response?.file,
                name: file?.name,
                originalFilename: file?.response?.originalName,
                url: `${API_HOST}${file?.response?.url}`,
            });
            return;
        }

        props?.onChange({ ...file });
    }

    const prepareList = (data: any) => {
        if(data?.name) {
            return [{...data}];
        } 

        return [];
    }

    const prepareModel = (ui: any) => {
        if ("ui:upload-model" in ui) {
            return ui["ui:upload-model"];
        }
        return modelName;
    }

    return (
        <>
            <label style={{fontWeight: 500 }}>{props?.schema?.title}</label>   
            <Upload
                name={fieldName}
                action={UPLOAD_URL}
                headers={getAuthHeaders()}
                fileList={prepareList(props?.formData)}
                listType="picture-card"
                onChange={handleChange}
                data={(file: any) => { 
                    return {
                        filename: file.name,
                        field: fieldName,
                        model: prepareModel(props?.uiSchema)
                    }
                }}                   
                maxCount={1}                                       
            >
                <UploadButton />
            </Upload>
        </>         
    );
};

export default ImageField;