import { useTranslate } from "@pankod/refine-core";
import { Form, Input, Radio } from "@pankod/refine-antd";
import { MapPointEdit } from 'components/form/MapPointEdit';
import ImageUpload from 'components/form/ImageUpload';
import ImagesUploadDND from 'components/form/ImagesUploadDND';
import LinkSelector from "components/form/LinkSelector";

const ProjectForm: React.FC<any> = (props: any) => {
    const { fprops }: any = props;
    const t = useTranslate();
    
    return(
        <Form {...fprops} layout="vertical">      
            <Form.Item
            label={t("projects.fields.path")}
            name="path"
            rules={[
                {
                required: true,
                message: `${t("messageError")}`
                },
            ]}
            >
            <Input />
            </Form.Item>
            <Form.Item
            label={t("projects.fields.name")}
            name="name"
            rules={[
                {
                required: true,
                message: `${t("messageError")}`
                },
            ]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label={t("projects.fields.status")}
            name="status"
            initialValue={"default"}
            rules={[{
                required: true,
                }]}>
            <Radio.Group name="status" buttonStyle="solid">
                <Radio.Button value={'default'}>по умолчанию</Radio.Button>                
                <Radio.Button value={'implemented'}>реализован</Radio.Button>
                <Radio.Button value={'hidden'}>скрыт</Radio.Button>                        
            </Radio.Group>
            </Form.Item>        
            
            <Form.Item
            label={t("projects.fields.description")}
            name="description"
            >
            <Input />
            </Form.Item>

            <Form.Item label={t("projects.fields.logo")}>
                <ImageUpload fieldName="logoFile" model="App\Entity\Project"/>            
            </Form.Item> 

            <Form.Item
                label={t("contacts.fields.mapData")}
                name="mapData"
                rules={[{
                    required: true,
                    message: `${t("messageError")}`
                }]}
                >
                    <MapPointEdit /> 
            </Form.Item>

            <Form.Item 
                label={t("projects.fields.images")}
                name="photos"
                >
                <ImagesUploadDND maxCount={10} fileFieldName="photoFile" model="App\Entity\ProjectPhoto"/>            
            </Form.Item>

            <div>
                <h2>{t("projects.promoBlock")}</h2>
                <Form.Item
                    label={t("projects.fields.promoTitle")}
                    name="promoTitle"
                    >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    label={t("projects.fields.promoDesc")}
                    name="promoDesc"
                    >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item label={t("projects.fields.promoImage")}>
                    <ImageUpload fieldName="promoImageFile" model="App\Entity\Project"/>            
                </Form.Item>

                <Form.Item
                    label={t("projects.fields.promoLink")}
                    name="promoLink"
                >
                    <LinkSelector />
                </Form.Item>
            </div>

            <div>
                <h2>{t("projects.buyBlock")}</h2>
                <Form.Item
                    label={t("projects.fields.buyTitle")}
                    name="buyTitle"
                    >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    label={t("projects.fields.buyDesc")}
                    name="buyDesc"
                    >
                    <Input.TextArea />
                </Form.Item>     

                <Form.Item
                    label={t("projects.fields.buyLink")}
                    name="buyLink"
                >
                    <LinkSelector />
                </Form.Item>

                <Form.Item
                    label={t("projects.fields.buyLink1")}
                    name="buyLink1"
                >
                    <LinkSelector />
                </Form.Item>
            </div>

        </Form>
    )
}

export default ProjectForm;