import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { INews } from "interfaces";
import useTitle from '../../utils/useTitle'
import Form from "./form";

const DataCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('news.fields.createNews')}`)

  const { formProps, saveButtonProps } = useForm<INews>({
    redirect: "list"
  });

  return (
    <Create saveButtonProps={saveButtonProps} title={t("news.fields.createNews")}>
      <Form fprops={formProps} /> 
    </Create>
  );
};

export default DataCreate;
