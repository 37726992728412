import { useTranslate } from "@pankod/refine-core";
import { Form, Upload } from "@pankod/refine-antd";
import { API_HOST, UPLOAD_URL } from "utils/constants";
import { getAuthHeaders } from 'utils/authProvider';

interface IEyeFileUpload {
    fieldName: string,
    model: string,
}

const FileUpload: React.FC<IEyeFileUpload> = ({ fieldName, model}) => {
    const t = useTranslate();

    const getValueFromEvent = (event: any) => {        
        if(event.file.status === "done") {
          const resp = event.file.response;    
          return [{ ...resp, url: `${API_HOST}${resp.url}`, name: resp.originalName }]
        }
        return event.fileList;      
    }

    return ( 
    <Form.Item
        name={fieldName}
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}                    
        noStyle
        >           

        <Upload.Dragger               
            name={fieldName}             
            action={UPLOAD_URL}
            headers={getAuthHeaders()}
            data={(file: any) => { 
                return {
                    filename: file.name,
                    field: fieldName,
                    model: model
                }
            }}                   
            maxCount={1}                                       
        >
            <p className="ant-upload-text">
                {t("projects.fields.drag")}
            </p>
        </Upload.Dragger>
    </Form.Item>
    );
}

export default FileUpload;