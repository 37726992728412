import { useTranslate } from "@pankod/refine-core";
import {
  Form,
  Input,
  Select,
  useSelect,
} from "@pankod/refine-antd";

import ImageUpload from 'components/form/ImageUpload';

const LandForm: React.FC<any> = ({ fprops, projectId }: any) => {
    const t = useTranslate(); 
    
    const { selectProps: selectProjectProps } = useSelect({
        resource: "projects",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });  


    return(
        <Form {...fprops} layout="vertical">            
            <Form.Item
                label={t("landscaping.fields.project")}
                name="project" 
                initialValue={parseInt(projectId)}
                rules={[
                    {
                    required: true,
                    },
                ]}                     
            
                >       
                <Select {...selectProjectProps} disabled={true} />
            </Form.Item>


            <Form.Item
                label={t("landscaping.fields.name")}
                name="name"            
                rules={[
                    {
                    required: true,
                    },
                ]}
                >
                <Input />
            </Form.Item>

            <Form.Item label={t("landscaping.fields.image")}>
                <ImageUpload fieldName="imageFile" model="App\Entity\Landscaping"/>            
            </Form.Item>    
                  
        </Form>
    )
}

export default LandForm;