import { Select, useSelect } from "@pankod/refine-antd";

const ProjectsSelect = (props: any) => { 
    const { selectProps } = useSelect({
        resource: "projects",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });
    
    const handleChange = (data: any) => {
        props?.onChange(data);   
    }

    console.log(props?.uiSchema.multiple)

    return (
        <>
            <label style={{fontWeight: 500 }}>{props?.schema?.title}</label>        
            <Select 
                {...selectProps } 
                allowClear 
                mode={props?.uiSchema.mode}
                onSearch={undefined} 
                showSearch={false} 
                onChange={handleChange}
                value={props?.formData}
            /> 
        </>      
    );
};


export default ProjectsSelect;