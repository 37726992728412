import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  useForm
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { IOffice } from "interfaces";
import useTitle from '../../utils/useTitle'
import Form from "./form";


export const OfficeCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('office.fields.createOffice')}`)

  const { formProps, saveButtonProps } = useForm<IOffice>();

  return (
    <Create saveButtonProps={saveButtonProps} title={t("office.fields.createOffice")}>
      <Form fprops={formProps} /> 
    </Create>
  );
};
