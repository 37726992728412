import { useEffect } from 'react'
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  ListButton,
  RefreshButton,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IUser } from "interfaces";
import useTitle from '../../utils/useTitle'
import { useSharedBreadCrumbs } from 'utils/useBreadCrumbs'

export const UsersEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('users.fields.editUsers')}`)

  const { formProps, saveButtonProps, id } = useForm<IUser>({
    redirect: "list"
  });
  const { breadcrumbs, set: setBreadCrumbs } = useSharedBreadCrumbs(); 

  useEffect(() => {
    const projectName = formProps?.form?.getFieldValue('email');
    if(projectName && id && breadcrumbs.length===0) {
      setBreadCrumbs([{ label: `Редактирование пользователя "${projectName}"`, path: `projects/edit/${id}`}]);
    }       
  }, [formProps, id, breadcrumbs.length, setBreadCrumbs]);

  return (
    <Edit saveButtonProps={saveButtonProps} title={t("users.fields.editUsers")} 
    pageHeaderProps={{
      extra: 
      <>
        <ListButton>{t("users.list")}</ListButton>
        <RefreshButton >{t("users.refresh")}</RefreshButton>
        </>}}> 
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("users.fields.title")}
          name="email"
          rules={[
            {
              required: true,
              message: `${t("messageError")}`
            },
          ]}
        >
          <Input />
        </Form.Item>  
        <Form.Item
          label={t("users.fields.password")}
          name="password"
          hasFeedback
        >
          <Input type='password'/>
        </Form.Item> 
        <Form.Item
          label={t("users.fields.repeatePassword")}
          name="repeatePassword"
          hasFeedback
          rules={[            
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`${t("users.fields.errorPass")}`));
              },
            })
          ]}>
          <Input type='password' />
        </Form.Item>          
      </Form>
    </Edit>
  );
};
