import ImageField from './imageField';
import mapField from './map';
import linkField from './linkField';
import projectSelect from './projectSelect';

export default {
    imageField: ImageField,
    mapField: mapField,
    linkField: linkField,
    projectSelect: projectSelect
}