import { IblockDataType } from "../blocks";
import linkType  from './common/linkType';
import imageType  from './common/imageType';

export default {
    type: "cottages",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок блока"
                },
                projects: {
                    type: ["array", "null"],
                    title: "Проекты",                                    
                }      
                                                     
            }
        },
        uiSchema: {
            projects: {
                "ui:field": "projectSelect",
                "mode": "multiple"
            }                    
        }
    }
} as IblockDataType;