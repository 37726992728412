import { useTranslate } from "@pankod/refine-core";
import { Form, Input } from "@pankod/refine-antd";
import ImageUpload from 'components/form/ImageUpload';

const ProjectForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate(); 

    return(
        <Form {...fprops} layout="vertical">
          <Form.Item
            label={t("metatag.fields.pattern")}
            name="pattern"
          >
            <Input />
          </Form.Item> 

          <Form.Item
            label={t("metatag.fields.title")}
            name="title"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("metatag.fields.keywords")}
            name="keywords"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("metatag.fields.description")}
            name="description"
          >
            <Input />
          </Form.Item>        
                        
      </Form>
    )
}

export default ProjectForm;