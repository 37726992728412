import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  Image
} from "@pankod/refine-antd";
import { IOffice } from "interfaces";
import useTitle from '../../utils/useTitle'

export const SocialList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('social.list')}`)

  const { tableProps, sorter } = useTable<any>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });
  return (
    <List>
      <Table 
        {...tableProps} 
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        
        <Table.Column
          dataIndex="image"
          key="image"
          title={t("social.fields.image")}        
          render={(value) =>  (value && <Image preview={false} height={25} src={value} />)}  
        />

        <Table.Column
          dataIndex="url"
          key="url"
          title={t("social.fields.url")}   
        />
   

        <Table.Column<any>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
