import { BankProgramList } from "./list";
import { BankProgramCreate } from "./create";
import { BankProgramEdit } from "./edit";
import { SolutionOutlined  } from '@ant-design/icons';

const Crud = {
    name: "bprograms",
    list: BankProgramList,
    create:BankProgramCreate,
    edit: BankProgramEdit,
    icon: <SolutionOutlined />,
    options: {
        label: "Программы",
        hasParent: true
    }
}

export default Crud;
