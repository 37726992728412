import { useEffect } from 'react'
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  ListButton,
  RefreshButton,
  useForm  
} from "@pankod/refine-antd";
import Form from "./form";

import "react-mde/lib/styles/css/react-mde-all.css";
import { IMenu } from "interfaces";
import useTitle from 'utils/useTitle';
import { useSharedBreadCrumbs } from 'utils/useBreadCrumbs';

export const MenuEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('menu.fields.editMenu')}`)
  
  const { formProps, saveButtonProps, id } = useForm<IMenu>({
    redirect: "list"
  });
  
  const { breadcrumbs, set: setBreadCrumbs } = useSharedBreadCrumbs(); 

  useEffect(() => {
    const projectName = formProps?.form?.getFieldValue('name');
    if(projectName && id && breadcrumbs.length===0) {
      setBreadCrumbs([{ label: `Редактирование меню "${projectName}"`, path: `projects/edit/${id}`}]);
    }       
  }, [formProps, id, breadcrumbs.length, setBreadCrumbs]);

  return (
    <Edit saveButtonProps={saveButtonProps} title={t("menu.fields.editMenu")} 
      pageHeaderProps={{
      extra: 
      <>
        <ListButton>{t("buttons.list")}</ListButton>
        <RefreshButton >{t("buttons.refresh")}</RefreshButton>
      </>
    }}>
         <Form fprops={formProps} />   
    </Edit>
  );
};
