import {useTranslate} from "@pankod/refine-core";
import {Form, Input, Select, useSelect} from "@pankod/refine-antd";

const BankProgramForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate();

    const { selectProps: selectBanksProps } = useSelect({
        resource: "banks",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });

    const { selectProps: selectProjectProps } = useSelect({
        resource: "projects",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });

    return (
        <Form {...fprops} layout="vertical">     
            
            <Form.Item
                label={t("bprograms.fields.name")}
                name="name"            
                rules={[
                    {
                    required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t("bprograms.fields.firstPay")}
                name="firstPay"            
                rules={[
                    {
                    required: true,
                    },
                ]}
            >
                <Input type={"number"}/>
            </Form.Item>

            <Form.Item
                label={t("bprograms.fields.period")}
                name="period"            
                rules={[
                    {
                    required: true,
                    },
                ]}
            >
                <Input type={"number"}/>
            </Form.Item>

            <Form.Item
                label={t("bprograms.fields.percent")}
                name="percent"            
                rules={[
                    {
                    required: true,
                    },
                ]}
            >
                <Input type={"number"}/>
            </Form.Item>

            <Form.Item
                label={t("bprograms.fields.projects")}
                name="projects">       
                    <Select {...selectProjectProps} mode="multiple"  />
            </Form.Item>

            <Form.Item
                label={t("bprograms.fields.bank")}
                name="bank"
                rules={[
                    {
                    required: true,
                    },
                ]}           
                
            >
                <Select {...selectBanksProps} />
            </Form.Item>                        
        </Form>
    )
}

export default BankProgramForm;