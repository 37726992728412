import { useState, CSSProperties } from "react";
import {
    useTitle, useNavigation,
    useLogout, useTranslate
} from "@pankod/refine-core";
import {
    AntdLayout,
    Menu,
    Grid,
    useMenu,
    Icons,
} from "@pankod/refine-antd";
import { Link } from "react-router-dom";

import Logo from "components/logo/Logo";

import "./aside.scss"


export const CustomMenu: React.FC = () => {
    const t = useTranslate();
    const Title = useTitle();

    const { SubMenu } = Menu;    
    const { menuItems } = useMenu();
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;

    const { mutate: logout } = useLogout();
    const { push } = useNavigation();

    const [current, setCurrent] = useState('1');

    const handleClick = (key: any) => {
        setCurrent(key);
    };

    return (
        <AntdLayout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            collapsedWidth={isMobile ? 0 : 80}
            breakpoint="lg"
            style={isMobile ? antLayoutSiderMobile : antLayoutSider}
        >
            <Logo collapsed={collapsed} />
            {Title && <Title collapsed={collapsed} />}
            <Menu
                selectedKeys={[current]} 
                inlineCollapsed 
                mode="inline" 
                onClick={({ key }) => {
                    if (key === "logout") {
                        logout();
                        return;
                    }
                    handleClick(key);
                    push(key as string);
                }}
            >
                { menuItems.map((data, index) => { 
                    const { route, label, icon, options } = data;
                    const subMenu = options?.subMenu;
                    const hasParent = options?.hasParent;        
                    
                    if(options?.hasParent) return;                    
                    return ( 
                        subMenu ?
                            <SubMenu key={index} icon={icon} title={label}>
                                {
                                    Object.entries(subMenu).map(([key, value]: any) => {                                        
                                        const subRoute = (value?.options?.route)?`/${value?.options?.route}`:"";                                   return (
                                            <Menu.Item key={`${value?.name}${subRoute}`} icon={value?.icon} >
                                                <Link to={value?.name}>
                                                    {value?.options?.label}
                                                </Link>
                                            </Menu.Item>
                                    );  
                                    })
                                }
                            </SubMenu>
                            
                        : ( <Menu.Item key={route} icon={icon} >
                                <Link to={route}>{label}</Link>
                            </Menu.Item>)
                    )
                })}

                <Menu.Item key="logout" className="buttonLogout" icon={<Icons.LogoutOutlined />}>
                    {t("buttons.logout")}
                </Menu.Item>
            </Menu>
        </AntdLayout.Sider>
    );
};

const antLayoutSider: CSSProperties = {
    position: "relative",
    backgroundColor: "rgba(42, 19, 46, 0.9)",
    overflow: "hidden"

};

const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
};