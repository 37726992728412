import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  ListButton,
  RefreshButton,
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import Form from "./form";
import useTitle from '../../utils/useTitle'

export const NewsEdit: React.FC<any> = ({ formProps, saveButtonProps, id}: any) => {  
 
  const t = useTranslate();

  useTitle(`${t('news.fields.editNews')}`)   

  return (    
    <Edit 
      saveButtonProps={saveButtonProps} 
      title={t("news.fields.editNews")} 
      pageHeaderProps={{
        extra: 
        <>
          <ListButton>{t("news.list")}</ListButton>
          <RefreshButton >{t("users.refresh")}</RefreshButton>
          </>}}> 
      <Form fprops={formProps} /> 
    </Edit>
  );
};
