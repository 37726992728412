import { useTranslate } from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  Edit,
  RefreshButton
} from "@pankod/refine-antd";
import useTitle from 'utils/useTitle';
import { useEffect } from 'react';
import { MapPointEdit } from 'components/form/MapPointEdit';
import LinkSelector from "components/form/LinkSelector";

export const EditContacts: React.FC<any> = () => {

  const t = useTranslate();
  const { formProps, saveButtonProps, setId, id } = useForm({
    action: "edit",
    redirect: false,
  });
  useEffect(()=> {
    if(id !== '1') {
      setId('1');
    }
  }, [id, setId]);

  useTitle(`${t('contacts.fields.edit')}`)

  return (
    <Edit        
      saveButtonProps={saveButtonProps}         
      title={t("contacts.fields.edit")}
      pageHeaderProps={{
        extra: (<><RefreshButton recordItemId="1">{t("buttons.refresh")}</RefreshButton></>)
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("contacts.fields.title")}
          name="header"
          rules={[{
            required: true,
            message: `${t("messageError")}`
          }]}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label={t("contacts.fields.address")}
          name="address"
          rules={[{
            required: true,
            message: `${t("messageError")}`
          }]}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label={t("contacts.fields.mapData")}
          name="mapData"
          rules={[{
            required: true,
            message: `${t("messageError")}`
          }]}
        >
            <MapPointEdit /> 
        </Form.Item>

        <Form.Item
          label={t("contacts.fields.tel")}
          name="phone"
          rules={[{
            required: true,
            message: `${t("messageError")}`
          }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={t("contacts.fields.email")}
          name="email"
          rules={[{
            required: true,
            message: `${t("messageError")}`
          }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label={t("contacts.fields.text")}
          name="text"
          rules={[{
            required: true,
            message: `${t("messageError")}`
          }]}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item
                label={t("contacts.fields.link")}
                name="link"
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}
            >
                <LinkSelector />
        </Form.Item>  

      </Form>
    </Edit>
  )
}