import { useEffect } from 'react'
import { Breadcrumb } from "@pankod/refine-antd";
import { useSharedBreadCrumbs } from 'utils/useBreadCrumbs';
import { useLocation } from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { routes } from './routes'
import "./breadcrumbs.scss"

const Breadcrumbs = () => {    
    const { breadcrumbs, set: setBreadCrumbs } = useSharedBreadCrumbs();
    const { pathname } = useLocation();
    const dafaultBreadcrumbs = useBreadcrumbs(routes);

    useEffect(() => {
        setBreadCrumbs([]) 
    }, [pathname, setBreadCrumbs]);  

    return (      
        <Breadcrumb separator=">" className='breadcrumbs' >
            { dafaultBreadcrumbs.map( (crumb, i) => (<Breadcrumb.Item key={i} href={`${crumb.match.pathname}`||'#'}>{crumb.breadcrumb}</Breadcrumb.Item>)) }  
            { breadcrumbs.map( (crumb, i) => (<Breadcrumb.Item key={i} href={`/${crumb.path}`||'#'}>{crumb.label}</Breadcrumb.Item>)) }                     
        </Breadcrumb>   
    );
};
export default Breadcrumbs