import {
  useTranslate,  
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  useModalForm,
  Modal,
} from "@pankod/refine-antd";
import { IHouse } from "interfaces";
import HouseForm from './form';

const resourseName = 'houses'

const ProjectHousesList: React.FC<any> = ({ projectId }: any) => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IHouse>({
    resource: resourseName,
    permanentFilter: [
      {
          field: "projectId",
          operator: "eq",
          value: projectId,
      },
   ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });


  // Create Modal
  const {
      modalProps: createModalProps,
      formProps: createFormProps,
      show: createModalShow
  } = useModalForm<IHouse>({
      resource: resourseName,
      action: "create",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });

  // Edit Modal
  const {
      modalProps: editModalProps,
      formProps: editFormProps,
      show: editModalShow,
  } = useModalForm<IHouse>({
      resource: resourseName,      
      action: "edit",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });
      
  return (
    <>
    <List 
      title={t("houses.list")}
      createButtonProps={{
        resource: "houses",
        onClick: () => {
            createModalShow();
        },
      }}         
    >
      <Table<IHouse> 
        {...tableProps}      
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("houses.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t("houses.fields.status")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="area"
          key="area"
          title={t("houses.fields.area")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("area", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="price"
          key="price"
          title={t("houses.fields.price")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("price", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="discountPrice"
          key="discountPrice"
          title={t("houses.fields.discountPrice")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("discountPrice", sorter)}
          sorter
        />    

        <Table.Column<IHouse>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton 
                hideText 
                size="small"
                onClick={() => editModalShow(`${record.id}`)}
              />
              <DeleteButton hideText size="small" resourceName={"houses"} recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>

    <Modal {...createModalProps}>
      <HouseForm fprops={createFormProps} projectId={projectId} />
    </Modal>
    <Modal {...editModalProps}>
      <HouseForm fprops={editFormProps} projectId={projectId} />
    </Modal>
    </>
  );
};

export default ProjectHousesList;
