import { EditContacts } from './edit';
import office from "../office";
import social from "../social";
import { ContactsOutlined } from '@ant-design/icons';

const contacts = {
    name: "contacts",   
    icon: <ContactsOutlined />,
    options: {
        label: "Контакты",
        route: "edit",      
    }
}

const ContactsCrud = {
    name: "contacts",
    edit: EditContacts, 
    options: {
        label: "Контакты",      
        subMenu: {
            contacts,
            office,
            social            
        }
    }
}


export default ContactsCrud;
