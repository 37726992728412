import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';

export default {
    type: "fullGallery",
    formProps: {
        schema: {
            type: "object",
            properties: {
                header: {
                    type: "string",
                    title: "Заголовок"
                },             
                text: {
                    type: "string",
                    title: "Описание основное"
                },
                displayThumbs: {
                    type: "boolean",
                    title: "Миниатюры вкл/выкл"
                },
                link: linkType("Кнопка основная"),
                link1: linkType("Ссылка второстепенная"),
                ytlink: {
                    type: "string",
                    title: "Ссылка на видео"
                },
                items: {
                    type: "array",
                    title: "Фото",
                    items: {
                        type: "object",                        
                        properties: {
                            photoFile: imageType,                          
                            text: {
                                type: "string",
                                title: "Описание"
                            },
                            id: {
                                type: "string",
                            }
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            link: {
                "ui:field": "linkField"
            },
            link1: {
                "ui:field": "linkField"
            }, 
            items: {
                items: {
                    text: {
                        "ui:widget": "textarea"
                    },
                    photoFile: {
                        "ui:field": "imageField"
                    },
                    id: {
                        'ui:widget': 'hidden'
                    }
                }
            }
        }
    }
} as IblockDataType;
