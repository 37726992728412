import { NewsList } from "./list";
import { NewsCreate } from "./create";
import { NewsEditTabs } from "./edittabs";
import { BookOutlined } from '@ant-design/icons';

const NewsCrud = {
    name: "news",
    list:  NewsList,
    create: NewsCreate,
    edit:  NewsEditTabs,
    icon: <BookOutlined />,
    options: {
        label: "Новости и акции"
    }
}

export default NewsCrud;
