import { MenuList } from "./list";
import { MenuCreate } from "./create";
import { MenuEdit } from "./edit";
import { MenuOutlined } from '@ant-design/icons';

const MenuCrud = {
    name: "menu",
    list:  MenuList,
    create: MenuCreate,
    edit:  MenuEdit,
    icon: <MenuOutlined />,
    options: {
        label: "Меню сайта"
    }
}

export default MenuCrud;
