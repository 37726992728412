import './index.scss';
import { useEffect } from 'react';
import { YMaps, Map, Placemark, MapState } from 'react-yandex-maps';
import { useState } from 'react';
import { useTranslate } from "@pankod/refine-core";
import { Modal } from "@pankod/refine-antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IMapState } from 'interfaces';

const defaultState:IMapState = {point: { lat: `59.94`, lng: `30.32`}, center: { lat: `59.94`, lng: `30.32`}, zoom: 10};

const getMapState = (value: IMapState) => {     
  return { center: [parseFloat(value.center.lat), parseFloat(value.center.lng)], zoom: value.zoom } as MapState     
};

const getPoint = (value: IMapState) => ([parseFloat(value.point.lat), parseFloat(value.point.lng)]);

export const MapPointEdit: React.FC<any> = ({ value, onChange, points }: any) => {

  const t = useTranslate(); 
  const [mapState, setMapState] = useState<IMapState>(defaultState);

  const updatePoint = (e: any) => {
    const lng = `${e.get('coords')[1]}`;
    const lat = `${e.get('coords')[0]}`;
    const newState = {...mapState, point: { lat: lat, lng: lng}} as IMapState;   
    setMapState(newState);
    onChange?.(newState);
  }

  const mapOnClick = (e: any) => {
    if(points) return;
    Modal.confirm({
      title: t(`map.change.comfirm`),
      icon: <ExclamationCircleOutlined />,    
      okText: t(`map.change.okBtn`),
      cancelText: t(`map.change.cancelBtn`),
      centered: true,
      onOk: () => {updatePoint(e)}
    }); 
  }

  const updateCenterAndZoom = (e: any) => {
    const center = e.get("target").getCenter()
    const zoom = e.get("target").getZoom()
    const lng = `${center[1]}`;
    const lat = `${center[0]}`;
    const newState = {...mapState, zoom: zoom, center: { lat, lng }} as IMapState;
    setMapState(newState);    
    onChange?.(newState);
  }

  useEffect(() => {
    if(value?.center?.lat && value?.center?.lng && value?.zoom) {
      setMapState(value);
    }
  }, [value]);  

  return (
    <>
      <YMaps >
        <Map
          onBoundsChange={updateCenterAndZoom}
          className='myMap'
          state={getMapState(mapState)}      
          onClick={mapOnClick}
        >          
          { points ? points : <Placemark geometry={getPoint(mapState)} /> }     
        </Map>
      </YMaps >     
    </>
  )
}