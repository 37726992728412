import List from "./list";
import Create from "./create";
import Edit from "./edittabs";
import { ApiOutlined } from '@ant-design/icons';

const Crud = {
    name: "pages",
    list:  List,
    create: Create,
    edit:  Edit,
    icon: <ApiOutlined />,
    options: {
        label: "Страницы сайта"
    }
}

export default Crud;
