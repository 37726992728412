import { IblockDataType } from "../blocks"
import imageType  from './common/imageType';

export default {
    type: "employees",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок блока"
                },
                text: {
                    type: "string",
                    title: "Описание"
                },
                items: {
                    type: "array",
                    title: "Лица",
                    items: {
                        type: "object",                        
                        properties: {
                          id: { type: "string" },
                          header: {
                            type: "string",
                            title: "ФИО"
                          },
                          description: {
                            type: ["string", "null"],
                            title: "Должность"
                          },
                          text: {
                            type: ["string", "null"],
                            title: "Описание"
                          },
                          photoFile: imageType,                         
                        }
                    }
                }                                         
            }
        },
        uiSchema: {           
            items: {
                items: {
                    id: {'ui:widget': 'hidden'},   
                    photoFile: {
                        "ui:field": "imageField"
                    },
                }
            }
        }
    }
} as IblockDataType;