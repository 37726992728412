import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  ListButton,
  RefreshButton,
  useForm  
} from "@pankod/refine-antd";
import Form from "./form";

import "react-mde/lib/styles/css/react-mde-all.css";
import { IBank } from "interfaces";
import useTitle from 'utils/useTitle';

export const BankEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('bank.editTitle')}`)
  
  const { formProps, saveButtonProps, id } = useForm<IBank>({
    redirect: "list"
  });


  return (
    <Edit saveButtonProps={saveButtonProps} title={t("bank.editTitle")} 
      pageHeaderProps={{
      extra: 
      <>
        <ListButton>{t("buttons.list")}</ListButton>
        <RefreshButton >{t("buttons.refresh")}</RefreshButton>
      </>
    }}>
         <Form fprops={formProps} />   
    </Edit>
  );
};
