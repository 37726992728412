import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters
} from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  Form,
  Input,
  FormProps,
  Row,
  Col,
  Icons,
  Button,
  Card
} from "@pankod/refine-antd";

import useTitle from '../../utils/useTitle'

export const MetaTagList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('metatag.list')}`);

  const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
      return (
          <Form layout="vertical" {...formProps}>
              <Form.Item label={t("metatag.filter")} name="url">
                  <Input
                      placeholder={t("metatag.urlTemplate")}
                      prefix={<Icons.SearchOutlined />}
                  />
              </Form.Item>

              <Form.Item>
              <div>
                <small>
                  Примеры шаблонов:
                  <ul style={{ paddingLeft: "0px", marginLeft: "15px"}}>
                    <li>* - соответствует всем url, самый низкий приоритет</li>
                    <li>/project* - соответствует url вида /project/one или /projects</li>
                    <li>/project/one - соответствует только url вида /project/one</li>
                  </ul>
                </small>
              </div>  
              </Form.Item>
          
              <Form.Item>
                  <Button htmlType="submit" type="primary">
                    {t("metatag.search")}
                  </Button>
              </Form.Item>
          </Form>
      );
  }

  const { tableProps, searchFormProps} = useTable<any>({
      onSearch: (params: any) => {
        const filters: CrudFilters = [];   

        filters.push(
            {
                field: "url",
                operator: "eq",
                value: params?.url,
            }            
        );

        return filters;
    },
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <Card>
            <Filter formProps={searchFormProps} />
        </Card>
      </Col>
      <Col lg={18} xs={24}>
        <List>
          <Table 
            {...tableProps} 
            showSorterTooltip={false}
            rowKey="id"
            pagination={{
              ...tableProps.pagination,
              showSizeChanger: true,
              locale:{ items_per_page: '' }
            }}
          >  

            <Table.Column
              dataIndex="pattern"
              key="pattern"
              title={t("metatag.fields.pattern")}   
            />

            <Table.Column
              dataIndex="title"
              key="title"
              title={t("metatag.fields.title")}   
            />

            <Table.Column
              dataIndex="keywords"
              key="keywords"
              title={t("metatag.fields.keywords")}   
            />

            <Table.Column
              dataIndex="description"
              key="description"
              title={t("metatag.fields.description")}   
            />  

            <Table.Column<any>
              title={t("table.actions")}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <DeleteButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
