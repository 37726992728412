import { IblockDataType } from "../blocks"

export default {
    type: "newsBlock",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок блока"
                },
                itemsType: {
                    title: "Тип публикаций",
                    type: "string",
                    enum: ["news", "campaign"],
                    enumNames: ["Новости", "Акции"]
                },
                projects: {
                    type: ["array", "null"],
                    title: "Проекты",                                    
                }                                         
            }
        },
        uiSchema: {           
            projects: {
                "ui:field": "projectSelect",
                "mode": "multiple"
            }
        }
    }
} as IblockDataType;