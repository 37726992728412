import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd";
import { INews } from "interfaces";
import useTitle from '../../utils/useTitle'

const DataList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('pages.pages')}`)

  const { tableProps, sorter } = useTable<INews>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ]
  });
  
  return (
    <List>
      <Table
        {...tableProps}
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale: { items_per_page: '' }
        }}
      >
        <Table.Column          
          dataIndex="id"
          key="id"
          title="ID"               
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="path"
          key="path"
          title={t("pages.fields.path")}          
          defaultSortOrder={getDefaultSortOrder("path", sorter)}
          sorter
        />       

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("pages.fields.name")}   
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />    

        <Table.Column<INews>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default DataList;
