import { useTranslate } from "@pankod/refine-core";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';


const UploadButton: React.FC<any> = () => {
    const t = useTranslate();
    const loading = false;

    return ( 
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{ t(`buttons.upload`) }</div>
        </div>
    );
}

export default UploadButton;
