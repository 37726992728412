import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';


export default {
    type: "smallGallery",
    formProps: {
        schema: {
            type: "object",
            properties: {
                header: {
                    type: "string",
                    title: "Заголовок"
                },           
           
                items: {
                    type: "array",
                    title: "Изображения",
                    items: {
                        type: "object",                        
                        properties: {
                            id: { type: "string" },
                            header: {
                                type: "string",
                                title: "Описание"
                            },
                            photoFile: imageType,                          
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            items: {
                items: {
                    id: {'ui:widget': 'hidden'},                     
                    photoFile: {
                        "ui:field": "imageField"
                    },
                    link: {
                        "ui:field": "linkField"
                    }                 
                }
            }
        }
    }
} as IblockDataType;