import { IMapProject } from 'interfaces';
import { Placemark } from 'react-yandex-maps';

const ProjectPlaceMark: React.FC<IMapProject> = ({ name, mapData }) => {
    if (!mapData) return (<></>);

    const pointProps = {
        geometry: [parseFloat(mapData?.point?.lat), parseFloat(mapData?.point?.lng)],
        properties: {
            hintContent: name,
            balloonContent: name,
            iconCaption: name
        },
        modules: ['geoObject.addon.balloon', 'geoObject.addon.hint']
    }

    return (<Placemark {...pointProps} />);
}

export default ProjectPlaceMark;