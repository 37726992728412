import { IblockDataType } from "../blocks"

export default {
    type: "docs",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок блока"
                },
                project: {
                    type: ["string", "null"],
                    title: "Проект",                                    
                }                                         
            }
        },
        uiSchema: {           
            project: {
                "ui:field": "projectSelect",                
            }
        }
    }
} as IblockDataType;