export declare type PlanTypes  = "panorama" | "svg" | "none";

export const types = [
    {
        label: "Панорама",
        value: "panorama",
    },
    {
        label: "SVG",
        value: "svg",
    },    
    {
        label: "Не отображать",
        value: "none"
    },
];