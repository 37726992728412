import React from "react";
import { useTranslate } from "@pankod/refine-core";
import { Form, Input } from "@pankod/refine-antd";

const DataForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate();
    return(
        <Form {...fprops} layout="vertical">  
        <Form.Item
          label={t("pages.fields.name")}
          name="name"
          rules={[{
              required: true,
            }]}>
          <Input />
        </Form.Item> 

        <Form.Item
          label={t("pages.fields.path")}
          name="path"
          rules={[{
              required: true,
            }]}>
          <Input />
        </Form.Item>            
      </Form>
    )
}

export default DataForm;