import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';


export default {
    type: "newsBigImage",
    formProps: {
        schema: {
            type: "object",
            properties: {
                photoFile: imageType                                      
            }
        },
        uiSchema: {           
            photoFile: {
                "ui:field": "imageField",
                "ui:upload-model": "App\\Entity\\ContentBlock\\NewsBigImage"
            }
        }
    }
} as IblockDataType;
