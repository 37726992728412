const imageType = {
    type: ["object", "null"],
    title: "Картинка",
    properties: {
        file: { },
        name: { },
        originalName: { },
        token: { }                                
    }
}

export default imageType;