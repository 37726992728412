import { IblockDataType } from "../blocks";
import mapType  from './common/mapType';

 const ProjectsMap: IblockDataType = {
    type: "projectsMap",
    formProps: {
        schema: {
            type: "object",
            properties: {
                mapData: mapType("Карта")                                      
            }
        },
        uiSchema: {           
            mapData: {
                "ui:field": "mapField"
            }
        }
    }
} as IblockDataType;

export default ProjectsMap;
