import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  Select,
  FilterDropdown, 
  FilterDropdownProps,
  useSelect
} from "@pankod/refine-antd";
import { IBProgram } from "interfaces";
import useTitle from 'utils/useTitle'

export const BankProgramList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('bprograms.list')}`);

  const { selectProps: selectProjectProps } = useSelect({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",               
    sort: [
        {
            field: "name",
            order: "asc",
        },
    ],
  });

  const { tableProps, sorter } = useTable<IBProgram>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table 
        {...tableProps} 
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("bprograms.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="projects"
          key="projects[]"
          title={t("bprograms.fields.projects")}
          render={(value) => {
            return value && value.map((em: string, i: number) => (<TagField key={i} value={em} />))
          }}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}  >
                <Select {...selectProjectProps} 
                    style={{ minWidth: 200 }}
                    mode="multiple"
                    placeholder={t("news.fields.projects")} />
            </FilterDropdown>
          )}
        />  

        <Table.Column
          dataIndex="firstPay"
          key="firstPay"
          title={t("bprograms.fields.firstPay")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("firstPay", sorter)}
          sorter
        />       

        <Table.Column
          dataIndex="period"
          key="period"
          title={t("bprograms.fields.period")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("period", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="percent"
          key="percent"
          title={t("bprograms.fields.percent")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("percent", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="bank"
          key="bank"
          title={t("bprograms.fields.bank")}
          render={(value) => <TagField color="green" value={value} />}     
        />

        <Table.Column<IBProgram>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
