import { useTranslate } from "@pankod/refine-core";
import { Form, Upload } from "@pankod/refine-antd";
import { API_HOST, UPLOAD_URL } from "utils/constants";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getAuthHeaders } from 'utils/authProvider';

interface IEyeFileUpload {
    fieldName: string,
    model: string,
    onChange?: Function
}

const ImageUpload: React.FC<IEyeFileUpload> = ({ fieldName, model, onChange }) => {
    const t = useTranslate();

    const getValueFromEvent = (event: any) => {
        let result = event.fileList;
        if(event.file.status === "done") {
          const resp = event.file.response;    
          result = [{ ...resp, url: `${API_HOST}${resp.url}`, name: resp.originalName }];
        }
        onChange && onChange(result);
        return result;    
    }

    const loading = false
    const UploadButton = () => (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{ t(`buttons.upload`) }</div>
        </div>
    ); 

    return ( 
    <Form.Item
        name={fieldName}
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}                          
        noStyle
        >           

        <Upload               
            name={fieldName}             
            action={UPLOAD_URL}
            headers={getAuthHeaders()}
            listType="picture-card"
            data={(file: any) => { 
                return {
                    filename: file.name,
                    field: fieldName,
                    model: model
                }
            }}                   
            maxCount={1}                                       
        >
           <UploadButton />
        </Upload>
    </Form.Item>
    );
}

export default ImageUpload;