import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { Tabs, useForm } from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Blocks } from 'components/blocks';
import Edit from './edit';
import { INews } from 'interfaces';
import useTitle from 'utils/useTitle';
import { useSharedBreadCrumbs } from 'utils/useBreadCrumbs';
import { useEffect } from 'react';
import TabsWithUrl from 'components/tabs';

const { TabPane } = Tabs;

const EditTabs: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(t('pages.editTitle'));
  
  const { formProps, saveButtonProps, id } = useForm<INews>();
  const { breadcrumbs, set: setBreadCrumbs } = useSharedBreadCrumbs();
      
  useEffect(() => {
    const dataTitle = formProps?.form?.getFieldValue('name');    
    if(dataTitle && id && breadcrumbs.length===0) {
      setBreadCrumbs([{ label: `Редактирование "${dataTitle}"`, path: `pages/edit/${id}`}]);
    }       
  }, [formProps, id, breadcrumbs]);

  return (
    <TabsWithUrl defaultActiveKey="main">
      <TabPane tab={'Основное'} key="main">
        <Edit formProps={formProps} saveButtonProps={saveButtonProps} id={id} />
      </TabPane>
      <TabPane tab={'Блоки страницы'} key="blocks">
        <Blocks resourse={"pages/blocks"} itemId={`${id}`} containerTitle={`Блоки страницы`}/>                  
      </TabPane>    
    </TabsWithUrl>
  );
};

export default EditTabs;
