import { List, Card, EditButton, Image, Space, Typography, Button, Spin, Empty} from "@pankod/refine-antd";
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { PlusCircleOutlined } from '@ant-design/icons';
import { BaseKey, useOne, useTranslate, useUpdate} from "@pankod/refine-core";
import { BlockDeleteButton } from 'components/buttons';
import { useState, useEffect } from 'react';
import Form from './form';

const { Text } = Typography

interface IBlocksProps {
    resourse: string;
    itemId?: BaseKey;
    containerTitle?: string;
}

export const Blocks: React.FC<IBlocksProps> = ({ resourse: blockResourse, itemId, containerTitle }: IBlocksProps) => {    

    const t = useTranslate();

    const [formData, setFormData] = useState<any>({visible: false});
    const [blocks, setBlocks] = useState<any[]>([]);

    const { mutate: updateBlocksOrder } = useUpdate<any>();

    const getListStyle = (isDraggingOver: boolean) => ({
        padding: 1,
        maxWidth: 600  
    });

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        padding: 1,
        margin: `0 0 5px 0`,
        borderRadius: '5px',
        border: isDragging ? "1px solid #67be23" : "1px solid #f0f2f5",
        ...draggableStyle
    });

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);      
        return result;
    };

    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {        
        if (!result.destination) {
        return;
        }

        const items = reorder(
            blocks,
            result.source.index,
            result.destination.index);  
    
        updateBlocksOrder({
            id: itemId || 0, 
            resource: `${blockResourse}/order`,
            values: items.map((em: any, pos: number)=>({ id: em.id, order: pos })),
            successNotification: { message: t(`block.order.success`), type: "success"},
            errorNotification: { message: t(`block.order.error`), type: "error"},
        });         

        setBlocks(items);
    }

    const { data, refetch: refetchBlocks, remove: removeBlocks, isLoading } = useOne<any>({
        id: itemId || 0,
        queryOptions: {cacheTime: 0}, 
        resource: blockResourse             
    });
        
    useEffect(() => {
        const items = data?.data;       
        if(items) {                          
            setBlocks(items);
        }
    }, [data]);   
    
    
    const showDrawer = (block: any, mode: "edit"|"create"="create") => {
        setFormData({...formData, block: block, visible: true, mode });
    };
    
    const onClose = () => {        
        setFormData({ visible: false });    
        removeBlocks();
        refetchBlocks();        
    };

    const onDeleteComplete = () => {
        refetchBlocks();        
    };

    const afterVisibleChange = (v: boolean) => {
    };    

    return (
        <>
        <List title={containerTitle} resource={blockResourse} canCreate={false}>
            <Button style={{marginBottom: 5}} icon={<PlusCircleOutlined/>} onClick={showDrawer}>Добавить блок</Button>
            { isLoading && 
            <div style={{margin: "10px", textAlign: "center"}}><Spin/></div> 
            }
            { (blocks.length===0) ? 
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
            <>
                <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (                
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            >
                            
                                { blocks.map((block: any, index: number) => (
                                    <Draggable key={block.id} draggableId={`${block.id}`} index={index}>
                                    {(provided, snapshot) => (
                                        
                                        <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                        >
                                            <Card resource={blockResourse} hoverable title={block.header} actions={[
                                                <EditButton type="text" onClick={() => showDrawer(block, "edit")} />,
                                                <BlockDeleteButton onDeleteComplete={onDeleteComplete} itemId={block.id} resource={blockResourse}/>                                         
                                            ]}>
                                                <Space>
                                                    <Image height={30} src={`/images/blocks/${block.content.type}.png`}></Image>
                                                    <Text>{t(`block.types.${block.content.type}`)}</Text>
                                                </Space>                                                                           
                                        
                                            </Card>
                                        </div>
                                    
                                    )}
                                    </Draggable>
                                ))}    
                        
                            {provided.placeholder}
                        </div>               
                        
                    )}            
                </Droppable>
                </DragDropContext>
            </>}
            <Button icon={<PlusCircleOutlined/>} onClick={showDrawer}>Добавить блок</Button>
        </List>
        <Form {...formData} onClose={onClose} afterVisibleChange={afterVisibleChange} resourse={blockResourse} itemId={itemId} />
        
        </>
    );
};