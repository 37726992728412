import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';

export default {
    type: "projectInfo",
    formProps: {
        schema: {
            type: "object",
            properties: {
                header: {
                    type: "string",
                    title: "Заголовок"
                },             
                text: {
                    type: "string",
                    title: "Текст"
                },
                link: linkType("Кнопка"),
                projectHeader: {
                    type: "string",
                    title: "Заголовок справа"
                },
                landStatus: {
                    type: "string",
                    title: "Статус земли"
                },
                landArea: {
                    type: "string",
                    title: "Площадь участков"
                },
                housesArea: {
                    type: "string",
                    title: "Площадь особняков"
                },                
                items: {
                    type: "array",
                    title: "Коммуникации",
                    items: {
                        type: "object",                        
                        properties: {                          
                          text: {
                            type: "string",
                            title: "Текст"
                          },
                          photoFile: imageType
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            link: {
                "ui:field": "linkField"
            }, 
            items: {
                items: {
                    text: {
                        "ui:widget": "textarea"
                    },
                    photoFile: {
                        "ui:field": "imageField"
                    }                                       
                }
            }
        }
    }
} as IblockDataType;
