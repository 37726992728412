import {
  useTranslate,
  IResourceComponentsProps
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  useSelect,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  Select,
  FilterDropdown, 
  FilterDropdownProps
} from "@pankod/refine-antd";
import { INews } from "interfaces";
import useTitle from '../../utils/useTitle'
import { getType, types} from './types'


export const NewsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('news.news')}`)

  const getTypes = () => (types.map(type => ({ text: t(`${type.label}`), value: type.value })))

  const { tableProps, sorter } = useTable<INews>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ]
  });

  const { selectProps: selectProjectProps } = useSelect({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",               
    sort: [
        {
            field: "name",
            order: "asc",
        },
    ],
  });
  
  return (
    <List>
      <Table
        {...tableProps}
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale: { items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="type"
          key="type[]"
          title={t("news.fields.type")}
          render={(value) => <TagField color={(value === 'news') ? 'green' : 'purple'} value={t(getType(value))} />}
          filters={getTypes()}          
          onFilter={(value, record: any) => record.type.indexOf(value) === 0}          
        />

        <Table.Column
          dataIndex="path"
          key="path"
          title={t("news.fields.path")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("path", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="header"
          key="header"
          title={t("news.fields.header")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("header", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="date"
          key="date"
          title={t("news.fields.date")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
         <Table.Column
          dataIndex="projects"
          key="projects[]"
          title={t("news.fields.projects")}
          render={(value) => {
            return value.map((em: string, i: number) => (<TagField key={i} value={em} />))
          }}         
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}  >
                <Select {...selectProjectProps} 
                    style={{ minWidth: 200 }}
                    mode="multiple"
                    placeholder={t("news.fields.projects")} />
            </FilterDropdown>
          )}
        />

        <Table.Column<INews>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
