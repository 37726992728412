import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd";
import { IProject } from "interfaces";
import useTitle from 'utils/useTitle';

const ProjectsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();


  const { tableProps, sorter } = useTable<IProject>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });
  useTitle(`${t('projects.list')}`)
  
  return (
    <List>
      <Table 
        {...tableProps} 
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t("projects.fields.status")}
          render={(value) => <TextField value={(value ==="implemented")?"реализован":"по умолчанию"} />}
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="path"
          key="path"
          title={t("projects.fields.path")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("path", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("projects.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="description"
          key="description"
          title={t("projects.fields.description")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("description", sorter)}
          sorter
        />      

        <Table.Column<IProject>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default ProjectsList;
