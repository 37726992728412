import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';


export default {
    type: "offers",
    formProps: {
        schema: {
            type: "object",
            properties: {
                items: {
                    type: "array",
                    title: "Предложения",
                    items: {
                        type: "object",                        
                        properties: {
                          id: {
                            type: "string",
                          },
                          header: {
                            type: "string",
                            title: "Заголовок",                            
                          },
                          text: {
                            type: ["string", "null"],
                            title: "Текст"
                          },
                          photoFile: imageType,
                          link: linkType("Клик на карточку"),                    
                          display: {
                            title: "Вид",
                            type: "string",
                            enum: ["small", "big"],
                            default: "small",                            
                          }
                        },
                        required: ["header"],
                    }
                }                         
            }
        },
        uiSchema: {
            items: {
                items: {
                    id: {
                        'ui:widget': 'hidden'
                    },                    
                    photoFile: {
                        "ui:field": "imageField"
                    },
                    link: {
                        "ui:field": "linkField"
                    },
                    linkOth: {
                        "ui:field": "linkField"
                    },                 
                }
            }
        }
    }
} as IblockDataType;
