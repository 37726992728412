import './forms.scss';
import { withTheme, FormProps } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';
import { Button, Divider } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import ArrayFieldTemplate from './ArrayFieldTemplate';
import fields from './fields';
import widgets from './widgets';

AntDTheme.ArrayFieldTemplate = ArrayFieldTemplate as any;
const Form = withTheme({ ...AntDTheme });

const SchemaForm: React.FC<FormProps<any>> = (props) => {
    const t = useTranslate();
   
    return (
        <Form {...props} fields={fields} widgets={widgets} showErrorList={false} noValidate={true} >
            {props.children}
            <Divider />
            <Button disabled={props.disabled || (!!props?.extraErrors)} htmlType="submit" type="primary">{t('buttons.save')}</Button>           
        </Form>
    )
};

export default SchemaForm;