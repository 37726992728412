import { useState, useEffect } from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  Form,
  Input,
  Select,
  Radio,
  useSelect
} from "@pankod/refine-antd";

import { types, PlanTypes } from "./types";
import FileUpload from 'components/form/FileUpload';

const PlanForm: React.FC<any> = ({ fprops, projectId, recordId }: any) => {
    const [currentType, setCurrentType] = useState<PlanTypes>("none")
    const t = useTranslate(); 
    const { selectProps: selectProjectProps } = useSelect({
        resource: "projects",
        optionLabel: "name",
        optionValue: "id",               
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });
    
    const typeChange = (value: PlanTypes) => {
        if(value !== currentType) {            
            setCurrentType(value);
        }
    }

    const typeData = fprops?.form?.getFieldValue('type');    
    useEffect(()=> {               
        typeChange(typeData)
    }, [typeData]);    
    

    return(
        <Form {...fprops} layout="vertical">            
            <Form.Item
            label={t("plan.fields.project")}
            name="project" 
            initialValue={parseInt(projectId)}
            rules={[
                {
                required: true,
                message: `${t("messageError")}`
                },
            ]}                     
         
            >       
                <Select {...selectProjectProps} disabled={true} />
            </Form.Item>           
            

            <Form.Item
                label={t("plan.fields.path")}
                name="path"               
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}
            >
                <Input />
            </Form.Item> 

            <Form.Item
                label={t("plan.fields.name")}
                name="name"               
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}
            >
                <Input />
            </Form.Item> 

            <Form.Item
                label={t("plan.fields.type")}
                name="type"
                initialValue={"panorama"}           
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}
            >       
                <Select options={types} onChange={typeChange}/>
            </Form.Item>

            <Form.Item 
                label={t("plan.fields.miniPlan")}
            >
                <FileUpload fieldName="miniPlanFile" model="App\Entity\ProjectPlan"/>        
            </Form.Item>

           {
                (currentType === "svg") &&
                <>
                    <Form.Item label={t("plan.fields.svgFile")}>
                        <FileUpload fieldName="svgFile" model="App\Entity\ProjectPlan"/>        
                    </Form.Item> 
                </>
            }
            {
                (currentType === "panorama") &&
                <>
                    <Form.Item
                        label={t("plan.fields.rebuild")}
                        name="rebuild"
                        initialValue={true}                                                 
                    >                     
                        <Radio.Group name="rebuild">
                            <Radio.Button value={true}>Да</Radio.Button>
                            <Radio.Button value={false}>Нет</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item 
                        label={t("plan.fields.tourSrc")}
                        rules={[
                            {
                                required: true,
                                message: `${t("messageError")}`
                            },
                        ]}
                    >
                        <FileUpload fieldName="tourSrcFile" model="App\Entity\ProjectPlan"/>        
                    </Form.Item>

                    <Form.Item 
                        label={t("plan.fields.tourObj")}
                        rules={[
                            {
                                required: true,
                                message: `${t("messageError")}`
                            },
                        ]}
                    >
                        <FileUpload fieldName="tourObjFile" model="App\Entity\ProjectPlan"/>        
                    </Form.Item>
                    
                    <Form.Item
                        label={t("plan.fields.tourObjDepth")}
                        name="depth"
                    >
                        <Input type={"number"} />
                    </Form.Item>

                    <Form.Item
                        label={t("plan.fields.tourObjRX")}
                        name="RX"
                    >
                        <Input type={"number"} />
                    </Form.Item>

                    <Form.Item
                        label={t("plan.fields.tourObjRY")}
                        name="RY"
                    >
                        <Input type={"number"} />
                    </Form.Item>

                    <Form.Item
                        label={t("plan.fields.tourObjRZ")}
                        name="RZ"
                    >
                        <Input type={"number"} />
                    </Form.Item> 

                    <Form.Item
                        label={`fov`}
                        name="FOV"
                    >
                        <Input type={"number"} />
                    </Form.Item> 

                    <Form.Item
                        label={`ath`}
                        name="ATH"
                    >
                        <Input type={"number"} />
                    </Form.Item>

                    <Form.Item
                        label={`atv`}
                        name="ATV"
                    >
                        <Input type={"number"} />
                    </Form.Item>
                </>
            }                
        </Form>
    )
}

export default PlanForm;