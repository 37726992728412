import "react-mde/lib/styles/css/react-mde-all.css";

import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import { Create, useForm } from "@pankod/refine-antd";
import { IMenu } from "interfaces";
import useTitle from 'utils/useTitle';
import Form from './form';

export const MenuCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('menu.fields.createMenu')}`)

  const { formProps, saveButtonProps } = useForm<IMenu>();

  return (
    <Create saveButtonProps={saveButtonProps} title={t("menu.fields.createMenu")}>
      <Form fprops={formProps} />   
    </Create>
  );
};
