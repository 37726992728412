import { useTranslate } from "@pankod/refine-core";
import { Form, Input } from "@pankod/refine-antd";
import { MapPointEdit } from 'components/form/MapPointEdit';

const ProjectForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate(); 

    return(
        <Form {...fprops} layout="vertical">
        <Form.Item
          label={t("office.fields.header")}
          name="header"
          rules={[{
              required: true,
              message: `${t("messageError")}`
            }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t("office.fields.phone")}
          name="phone"
          rules={[{
              required: true,
              message: `${t("messageError")}`
            }]}>
          <Input />
        </Form.Item> 
        <Form.Item
          label={t("office.fields.email")}
          name="email"
          rules={[{
              required: true,
              message: `${t("messageError")}`
            }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t("contacts.fields.mapData")}
          name="mapData"
          rules={[{
            required: true,
            message: `${t("messageError")}`
          }]}
        >
            <MapPointEdit /> 
        </Form.Item> 
        <Form.Item
          label={t("office.fields.text")}
          name="text"
          rules={[{
              required: true,
              message: `${t("messageError")}`
            }]}>
          <Input.TextArea />
        </Form.Item>                    
      </Form>
    )
}

export default ProjectForm;