import { AuthProvider } from "@pankod/refine-core";
import { AxiosInstance } from 'axios';

const TOKEN_KEY = "refine-auth";

const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
}

export const getAuthHeaders = (token?: string) => {
  const tk = token || localStorage.getItem(TOKEN_KEY); 
  return {
    Authorization: `Bearer ${tk}`,
  };
}

const addTokenToClientHeaders = (token: string, axiosInstance: AxiosInstance) => {
  axiosInstance.defaults.headers.common = getAuthHeaders(token);
};

const authProvider = (
  loginUrl: string,
  axiosInstance: AxiosInstance,
): AuthProvider => ({  
  login: async ({ username, password }) => {    
    const request = new Request(loginUrl, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
    return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                  return Promise.reject(new Error(response.statusText));
                }
                return response.json();
            })
            .then(({ token }) => {
              localStorage.setItem(TOKEN_KEY, token);
              addTokenToClientHeaders(token, axiosInstance)                            
              return Promise.resolve();
            });
    
  },  
  logout: () => {
    logout();
    return Promise.resolve();
  },
  checkError: (error) => {
    console.log("@checkError")
    const status = error.response.status;   
    if (status === 401 || status === 403) {
      logout();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);   
   
    if (token) {
      addTokenToClientHeaders(token, axiosInstance)  
      return Promise.resolve();
    }   

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1,
    });
  },

});

export const createAuthProvider = (loginUrl: string, axiosInstance: AxiosInstance) => {
  return authProvider(loginUrl, axiosInstance)
}
