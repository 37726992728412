import { IblockDataType } from "../blocks";

export default {
    type: "calc",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок"
                },
                priceMin: {
                    type: "number",
                    title: "Цена (минимум)"
                },
                priceMax: {
                    type: "number",
                    title: "Цена (максимум)"
                },
                periodMin: {
                    type: "number",
                    title: "Срок (минимум)"
                },
                periodMax: {
                    type: "number",
                    title: "Срок (максимум)"
                },
                payMin: {
                    type: "number",
                    title: "Первоначальный взнос (минимум)"
                }, 
                payMax: {
                    type: "number",
                    title: "Первоначальный взнос (максимум)"
                }, 
                text: {
                    type: ["string", "null"],
                    title: "Текст"
                },                                 
            }
        }       
    }
} as IblockDataType;
