import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';
import linkType  from './common/linkType';

export default {
    type: "promo",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок блока"
                },
                text: {
                    type: "string",
                    title: "Описание"
                },
                link: linkType("Кнопка"),
                items: {
                    type: "array",
                    title: "Предложения",
                    items: {
                        type: "object",                        
                        properties: {
                            photoFile: imageType,
                            link: linkType("Кнопка"),
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            text: {
                "ui:widget": "textarea"
            },
            link: {
                "ui:field": "linkField"
            },
            items: {
                items: {                    
                    photoFile: {
                        "ui:field": "imageField"
                    },
                    link: {
                        "ui:field": "linkField"
                    }                 
                }
            }
        }
    }
} as IblockDataType;