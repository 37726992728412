import './index.scss';
import { useTranslate } from "@pankod/refine-core";
import { Input, Select, Checkbox, useSelect } from "@pankod/refine-antd";
import FileUpload from 'components/form/FileUpload';

const PageSelect: React.FC<any> = (props: any) => {

    const { selectProps } = useSelect({
        resource: `link/${props?.type}`,
        optionLabel: "name",
        optionValue: "id",      
    });

    const selectChange = (value: any) => props?.onChange(value);

    return (
        <Select {...props} {...selectProps} allowClear onChange={selectChange} />
    );
}

const ForTypeValueIput: React.FC<any> = (props: any) => {
    const t = useTranslate();
    const inputChangeHandler = (e: any) => {
        const value =  e?.target?.value;     
        let result: any = {}
        const type =`${props?.type}` as KeyType; 
        result[type] = value;
        props?.onChange(result);
    }

    const pageSelectChangeHandler = (value: any) => {     
        let result: any = {}
        const type =`${props?.type}` as KeyType; 
        result[type] = value;
        props?.onChange(result);
    }
    
    if(props?.type === 'contacts' || props?.type === 'newsList' || props?.type === 'newton') {
        return (<></>);
    }

    if(props?.type === 'page' || props?.type === 'news') {
        return (<PageSelect {...props} value={props?.value[props?.type]} onChange={pageSelectChangeHandler} placeholder={t(`link.forTypeValueIput.${props?.type}`)} />);
    }
    
    if(props?.type === 'file') {
        return (<FileUpload fieldName="uploadedFile" model="App\Entity\Link"/> );
    } 

    return (<Input value={props?.value[props?.type]} placeholder={t(`link.forTypeValueIput.${props?.type}`)} onChange={inputChangeHandler}/>)
}

const LinkSelector: React.FC<any> = (props: any) => {
    const t = useTranslate();

    const { selectProps: typesProps } = useSelect({
        resource: "link/types",
        optionLabel: "label",
        optionValue: "value",      
    });

    const changeType = (value: any) => {
        if(value) {
            props?.onChange({...props?.value, type: value});
        } else {
            props?.onChange({ type: null });
        }
    };
    
    const changeHeader = (e: any) => {
        props?.onChange({...props?.value, header: e?.target?.value})
    };    

    const changeTypeValue = (value: any) => {              
        props?.onChange({ 
            type: props?.value?.type, 
            header: props?.value?.header,
            popup: props?.value?.popup, 
            ...value 
        });      
    }

    const changePopup = (e: any) => props?.onChange({...props?.value, popup: e?.target?.checked});   
   

    return (
        <div className='form-link-field'>             
            <Select {...typesProps } value={props?.value?.type} placeholder={t(`link.typeSelect`)} 
                onChange={changeType} allowClear showSearch={false} onSearch={undefined} />       
            { props?.value?.type &&
            <>
                <Input value={props?.value?.header} placeholder={t(`link.header`)} onChange={changeHeader}/>
                <Checkbox checked={props?.value?.popup} onChange={changePopup}>{t(`link.popup`)}</Checkbox>
                <ForTypeValueIput value={props?.value} type={props?.value?.type} onChange={changeTypeValue}/>
            </>            
            }                        
        </div>
    );
}

export default LinkSelector;