import {useTranslate, IResourceComponentsProps} from "@pankod/refine-core";
import {
  Create,
  useForm,
} from "@pankod/refine-antd";

import Form from "./form";

import "react-mde/lib/styles/css/react-mde-all.css";
import { IProject } from "interfaces";
import useTitle from '../../utils/useTitle'

const ProjectsCreate: React.FC<IResourceComponentsProps> = () => {  
  const { formProps, saveButtonProps } = useForm<IProject>();
  
  const t = useTranslate();
  useTitle(`${t('projects.fields.createProject')}`)
  return (
    <Create saveButtonProps={saveButtonProps} title={t("projects.fields.createProject")}>
      <Form fprops={formProps} />      
    </Create>
  );
};

export default ProjectsCreate;
