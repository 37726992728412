import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import { Create, useForm } from "@pankod/refine-antd";
import { IBProgram } from "interfaces";
import useTitle from 'utils/useTitle';
import Form from './form';

export const BankProgramCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('bprograms.createTitle')}`)

  const { formProps, saveButtonProps } = useForm<IBProgram>();

  return (
    <Create saveButtonProps={saveButtonProps} title={t("bprograms.createTitle")}>
      <Form fprops={formProps} />   
    </Create>
  );
};
