import { useEffect } from 'react';
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { IProject } from "interfaces";
import {
  Edit,
  useForm,
  Tabs,
  ListButton,
  RefreshButton
} from "@pankod/refine-antd";

import Form from "./form";
import Lands from "./lands/list";
import Houses from "./houses/list";
import Pages from "./pages/list";
import Plan from "./plan/list";
import Communications from "./communications/list";
import Landscaping from "./landscaping/list";
import Places from "./places/list";
import Import from "./import/list";

import "react-mde/lib/styles/css/react-mde-all.css";
import useTitle from 'utils/useTitle';
import { useSharedBreadCrumbs } from 'utils/useBreadCrumbs';
import TabsWithUrl from 'components/tabs';

const { TabPane } = Tabs;

const ProjectsEdit: React.FC<IResourceComponentsProps> = (prop) => {
  const t = useTranslate();
  useTitle(`${t('projects.fields.editProject')}`);  

  
  const  { formProps, saveButtonProps, id, onFinish } = useForm<IProject>();
  const { breadcrumbs, set: setBreadCrumbs } = useSharedBreadCrumbs();  
  
  useEffect(() => {
    const projectName = formProps?.form?.getFieldValue('name');
    if(projectName && id && breadcrumbs.length===0) {
      setBreadCrumbs([{ label: `Редактирование проекта "${projectName}"`, path: `projects/edit/${id}`}]);
    }       
  }, [formProps, id, breadcrumbs.length, setBreadCrumbs]);

  return ( 
      <TabsWithUrl defaultActiveKey="main">
        <TabPane tab={t("projects.fields.basic")} key="main">
          <Edit 
            saveButtonProps={saveButtonProps} title={t("projects.fields.editProject")} 
            pageHeaderProps={{
              extra: 
              <>
                <ListButton>{t("projects.list")}</ListButton>
                <RefreshButton >{t("projects.refresh")}</RefreshButton>
                </>}}>
            <Form fprops={formProps} /> 
          </Edit>
        </TabPane>
        <TabPane tab={t("projects.fields.lands")} key="lands">
          <Lands projectId={id} />
        </TabPane>
        <TabPane tab={t("projects.fields.cottage")} key="cottages">
          <Houses projectId={id} />
        </TabPane>  
        <TabPane tab={t("projects.fields.pages")} key="pages">
          <Pages projectId={id} />
        </TabPane>
        <TabPane tab={t("projects.fields.plan")} key="plan">
          <Plan projectId={id} />
        </TabPane>
        <TabPane tab={t("projects.fields.communications")} key="communications">
          <Communications projectId={id} />
        </TabPane>
        <TabPane tab={t("projects.fields.landscaping")} key="landscaping">
          <Landscaping projectId={id} />
        </TabPane>
        <TabPane tab={t("projects.fields.places")} key="places">
          <Places projectId={id} />
        </TabPane>
        <TabPane tab={t("projects.fields.import")} key="import">
          <Import projectId={id} />
        </TabPane>
    </TabsWithUrl> 
  );
};

export default ProjectsEdit;
