import { useEffect } from 'react'
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  ListButton,
  RefreshButton,
  useForm
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { IOffice } from "interfaces";
import useTitle from '../../utils/useTitle'
import Form from "./form";
import { useSharedBreadCrumbs } from 'utils/useBreadCrumbs'


export const MetaTagEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('office.fields.editOffice')}`)

  const { formProps, saveButtonProps, id } = useForm<IOffice>();
  const { breadcrumbs, set: setBreadCrumbs } = useSharedBreadCrumbs();  
  
  useEffect(() => {
    const projectName = formProps?.form?.getFieldValue('name');
    if(projectName && id && breadcrumbs.length===0) {
      setBreadCrumbs([{ label: `Редактирование офиса "${projectName}"`, path: `projects/edit/${id}`}]);
    }       
  }, [formProps, id, breadcrumbs.length, setBreadCrumbs]);

  return (
    <Edit saveButtonProps={saveButtonProps} title={t("office.fields.editOffice")} 
    pageHeaderProps={{
      extra: 
      <>
        <ListButton>{t("buttons.list")}</ListButton>
        <RefreshButton >{t("buttons.refresh")}</RefreshButton>
        </>}}> 
        <Form fprops={formProps} /> 
    </Edit>
  );
};
