export const routes = [
    { path: '/', breadcrumb: 'Главная' },
    { path: '/menu', breadcrumb: 'Меню' },
    { path: '/menu/create', breadcrumb: "Создать меню" },
    { path: '/menu/edit', breadcrumb: null },
    { path: '/menu/edit/:id', breadcrumb: null },
    { path: '/users', breadcrumb: 'Пользователи' },
    { path: '/users/create', breadcrumb: 'Создать пользователя' },
    { path: '/users/edit', breadcrumb: null },
    { path: '/users/edit/:id', breadcrumb: null },
    { path: '/projects', breadcrumb: 'Проекты' },
    { path: '/projects/create', breadcrumb: 'Создать проект' },
    { path: '/projects/edit', breadcrumb: null },
    { path: '/projects/edit/:id', breadcrumb: null },
    { path: '/news', breadcrumb: 'Новости' },
    { path: '/news/create', breadcrumb: 'Создать новость' },
    { path: '/news/edit', breadcrumb: null },
    { path: '/news/edit/:id', breadcrumb: null },
    { path: '/contacts/', breadcrumb: null },
    { path: '/contacts/edit/', breadcrumb: "Контакты" },

    { path: '/office', breadcrumb: "Офисы" },
    { path: '/office/create', breadcrumb: "Создать офис" },
    { path: '/office/edit', breadcrumb: null },
    { path: '/office/edit/:id', breadcrumb: null },

    { path: '/docs', breadcrumb: "Документы" },
    { path: '/docs/create', breadcrumb: "Добавить документ" },
    { path: '/docs/edit', breadcrumb: null },
    { path: '/docs/edit/:id', breadcrumb: null },

    { path: '/banks', breadcrumb: "Банки" },
    { path: '/banks/create', breadcrumb: "Добавить банк" },
    { path: '/banks/edit', breadcrumb: "Редактирование банка" },
    { path: '/banks/edit/:id', breadcrumb: "Редактирование банка" },

    { path: '/pages', breadcrumb: "Страницы сайта" },
    { path: '/pages/create', breadcrumb: "Создать страницу" },
    { path: '/pages/edit', breadcrumb: null },
    { path: '/pages/edit/:id', breadcrumb: null },

    { path: '/bprograms', breadcrumb: "Банковские программы" },
    { path: '/bprograms/create', breadcrumb: "Добавить программу" },
    { path: '/bprograms/edit', breadcrumb: null },
    { path: '/bprograms/edit/:id', breadcrumb: null},

    { path: '/metatag', breadcrumb: "Метатеги" },
    { path: '/metatag/create', breadcrumb: "Добавить метатег" },
    { path: '/metatag/edit', breadcrumb: null },
    { path: '/metatag/edit/:id', breadcrumb: null},

    { path: '/social', breadcrumb: "Соцсети" },
    { path: '/social/create', breadcrumb: "Добавить" },
    { path: '/social/edit', breadcrumb: null },
    { path: '/social/edit/:id', breadcrumb: null}
    
];