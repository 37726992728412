import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  ListButton,
  RefreshButton,
  useForm
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import Form from "./form";
import useTitle from '../../utils/useTitle'
import { IDocs } from "interfaces";

export const DocsEdit: React.FC<any> = () => {  
 
  const t = useTranslate();
  useTitle(`${t('docs.editTitle')}`);
  
  const { formProps, saveButtonProps } = useForm<IDocs>(); 

  return (    
    <Edit 
      saveButtonProps={saveButtonProps} 
      title={t("docs.editTitle")} 
      pageHeaderProps={{
        extra: 
        <>
          <ListButton>{t("buttons.list")}</ListButton>
          <RefreshButton >{t("buttons.refresh")}</RefreshButton>
          </>}}> 
      <Form fprops={formProps} /> 
    </Edit>
  );
};
