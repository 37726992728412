import {useState} from 'react';
import {
  BaseKey,
  useTranslate,  
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  useModalForm,
  Modal,
} from "@pankod/refine-antd";
import { IHouse } from "interfaces";
import PageForm from './form';

const resourseName = 'pages'

const ItemsList: React.FC<any> = ({ projectId }: any) => {
  const t = useTranslate();
  const [pageId, setPageId] = useState<string|null>(null);

  const { tableProps, sorter } = useTable<IHouse>({
    resource: resourseName,
    permanentFilter: [
      {
          field: "projectId",
          operator: "eq",
          value: projectId,
      },
   ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });


  // Create Modal
  const {
      modalProps: createModalProps,
      formProps: createFormProps,
      show: createModalShow
  } = useModalForm<any>({
      resource: resourseName,
      action: "create",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });

  // Edit Modal
  const {
      modalProps: editModalProps,
      formProps: editFormProps,
      show: editModalShow,      
  } = useModalForm<any>({   
      resource: resourseName,      
      action: "edit",
      redirect: false,
      warnWhenUnsavedChanges: true,
  });
      
  return (
    <>
    <List 
      title={t("pages.list")}
      createButtonProps={{
        resource: "pages",
        onClick: () => {
            createModalShow();
            setPageId(null);
        },
      }}         
    >
      <Table<IHouse> 
        {...tableProps}      
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="path"
          key="path"
          title={t("pages.fields.path")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("path", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("pages.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />       

        <Table.Column<IHouse>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton 
                hideText 
                size="small"
                onClick={() => {
                  setPageId(`${record.id}`);
                  editModalShow(`${record.id}`)
                }}
              />
              <DeleteButton hideText size="small" resourceName={"pages"} recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>

    <Modal {...createModalProps} title={t('pages.createTitle')}>
      <PageForm fprops={createFormProps} projectId={projectId} />
    </Modal>
    <Modal {...editModalProps} title={t('pages.editTitle')}>
      <PageForm fprops={editFormProps} projectId={projectId} pageId={`${pageId}`}/>
    </Modal>
    </>
  );
};

export default ItemsList;
