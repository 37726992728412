import {useTranslate} from "@pankod/refine-core";
import {Form, Input, Select} from "@pankod/refine-antd";
import LinkSelector from "components/form/LinkSelector";

const MenuForm: React.FC<any> = ({ fprops }: any) => {
    const t = useTranslate();
    
    const statuses = [
        {
            label: t("menu.status.true"),
            value: true,
        },
        {
            label: t("menu.status.false"),
            value: false,
        }
    ];
    
    const positions = [
        {
            label: t("menu.position.left"),
            value: "left",
        },
        {
            label: t("menu.position.right"),
            value: "right",
        }
    ]; 

    return (
        <Form {...fprops} layout="vertical">
            <Form.Item
                label={t("menu.fields.isActive")}
                name="is_active"
                initialValue={false}    
                rules={[{
                    required: true,
                }]}
            >
                <Select options={statuses} />    
            </Form.Item>

            <Form.Item
                label={t("menu.fields.position")}
                name="position"
                initialValue={"left"}    
                rules={[{
                    required: true,
                }]}
            >
                <Select options={positions} />    
            </Form.Item>
            
            <Form.Item
                label={t("menu.fields.sort")}
                name="sort"
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}
            >
                <Input type={"number"}/>
            </Form.Item>

            <Form.Item
                label={t("menu.fields.name")}
                name="name"
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}
            >
                <Input />
            </Form.Item>            

            <Form.Item
                label={t("menu.fields.link")}
                name="link"
                rules={[
                    {
                    required: true,
                    message: `${t("messageError")}`
                    },
                ]}
            >
                <LinkSelector />
            </Form.Item>            
        </Form>
    )
}

export default MenuForm;