import { IblockDataType } from "../blocks"

 const Text: IblockDataType = {
    type: "textProperty",
    formProps: {
        schema: {
            type: "object",
            properties: {
                
                header: {
                    type: "string",
                    title: "Заголовок"
                },
                text: {
                    type: "string",
                    title: "Текст"
                },         
            }
        },
        uiSchema: {
            text: {
                "ui:widget": "textarea"
            }
        }
    }
}

export default Text;
