import { IblockDataType } from "../blocks";
import linkType  from './common/linkType';

export default {
    type: "headerWithDesc",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок блока"
                }, 
                description: {
                    type: "string",
                    title: "Описание"
                },
                link: linkType("Кнопка"),
                linkOth: linkType("Кнопка второстепенная"),                                        
            }
        },
        uiSchema: {
            description: {
                "ui:widget": "textarea"
            },
            link: {
                "ui:field": "linkField"
            },
            linkOth: {
                "ui:field": "linkField"
            }          
        }
    }
} as IblockDataType;