import "@pankod/refine-antd/dist/styles.min.css";
import { Refine } from "@pankod/refine-core";
import { notificationProvider, Layout } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import axios from 'axios';
import dataProvider from "@pankod/refine-simple-rest";
import { createAuthProvider } from "./utils/authProvider";
import { useTranslation } from "react-i18next";
import { Header } from "components/layout";
import { LoginPage } from "pages/login";
import { CustomMenu } from "components/layout/aside/aside";
import { ErrorComponent } from "@pankod/refine-antd";

import users from "pages/users";
import projects from "pages/projects";
import menu from "pages/menu";
import news from "pages/news";
import contacts from "pages/contacts";
import office from "pages/office";
import docs from "pages/docs";
import pages from "pages/pages";
import bank from "pages/bank";
import bprograms from "pages/bprograms";
import social from "pages/social";
import metatag from "pages/metatag";


import { API_URL } from "utils/constants";
import "styles/style.scss"

const axiosInstance = axios.create();

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      reactQueryClientConfig={{
        defaultOptions: {
          queries: {
            retry: 1,
            retryDelay: 1000,
          }          
        },
      }}     
      Sider={CustomMenu}
      routerProvider={routerProvider}
      notificationProvider={notificationProvider}
      Layout={Layout}
      dataProvider={dataProvider(API_URL, axiosInstance)}
      authProvider={createAuthProvider(`${API_URL}/login_check`, axiosInstance)}
      LoginPage={LoginPage}
      resources={[users, menu, pages, projects, bank, bprograms, docs, news, contacts, office, social, metatag]}
      i18nProvider={i18nProvider}
      Header={Header}
      catchAll={<ErrorComponent />}
    />
  );
}

export default App;
