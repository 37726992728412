import { BankList } from "./list";
import { BankCreate } from "./create";
import { BankEdit } from "./edit";
import { BankOutlined } from '@ant-design/icons';
import bprograms from "../bprograms";

const banks = {
    name: "banks",   
    icon: <BankOutlined />,
    options: {
        label: "Банки",        
    }
}

const Crud = {
    name: "banks",
    list:  BankList,
    create: BankCreate,
    edit:  BankEdit,
    icon: <BankOutlined />,
    options: {
        label: "Ипотека",
        subMenu: {
            banks,
            bprograms            
        }
    }
}

export default Crud;
