import { DocsList } from "./list";
import { DocsCreate } from "./create";
import { DocsEdit } from "./edit";
import { PaperClipOutlined } from '@ant-design/icons';

const DocsCrud = {
    name: "docs",
    list:  DocsList,
    create: DocsCreate,
    edit:  DocsEdit,
    icon: <PaperClipOutlined />,
    options: {
        label: "Документы"
    }
}

export default DocsCrud;
