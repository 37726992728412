import { JSONSchema7 } from 'json-schema';
import { UiSchema } from '@rjsf/core';

import textProperty from './types/textProperty';
import offers from './types/offers';
import awards from './types/awards'; 
import cardsWithHeader from './types/cardsWithHeader'; 
import promo from './types/promo'; 
import headerWithDesc from './types/headerWithDesc'; 
import projectPromo from './types/projectPromo'; 
import newsBlock from './types/newsBlock';
import fullGallery from './types/fullGallery';
import projectInfo from './types/projectInfo';
import smallGallery from './types/smallGallery';
import imageText from './types/imageText';
import bigGallery from './types/bigGallery';
import newsGallery from './types/newsGallery';
import newsBigImage from './types/newsBigImage';
import bigImageText from './types/bigImageText';
import cards from './types/cards';
import projectsMap from './types/projectsMap';
import villageCards from './types/villageCards';
import docs from './types/docs';
import calc from './types/calc';
import employees from './types/employees';
import cottages from './types/cottages';
import history from './types/history';

export declare type BlockTypes  =
    "textProperty" |
    "offers" |
    "awards" |
    "cardsWithHeader" |
    "promo" |
    "headerWithDesc" |
    "projectPromo" |
    "newsBlock" | 
    "fullGallery" |
    "projectInfo" | 
    "smallGallery" |
    "imageText" |
    "bigGallery" |
    "newsGallery" |
    "newsBigImage" | 
    "bigImageText" |
    "cards" |
    "projectsMap" |
    "villageCards" |
    "docs" |
    "calc" |
    "employees" |
    "cottages" |
    "history"
    ;   

export interface IblockDataType {
    type: BlockTypes,
    formProps: {
        schema: JSONSchema7,
        uiSchema?: UiSchema        
    }
}

export const blocks: IblockDataType[] = [
    textProperty,
    offers,
    awards,
    cardsWithHeader,
    promo,
    headerWithDesc,
    projectPromo,
    newsBlock,
    fullGallery,
    projectInfo,
    smallGallery,
    imageText,
    bigGallery,
    newsGallery,
    newsBigImage,
    bigImageText,
    cards,
    projectsMap,
    villageCards,
    docs,
    calc,
    employees,
    cottages,
    history
];

export const getBlockDataTypeProps = (type: BlockTypes) => (blocks.find(b=>(b.type===type))?.formProps || {schema: {}})