import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
} from "@pankod/refine-antd";
import useTitle from '../../utils/useTitle'



import "react-mde/lib/styles/css/react-mde-all.css";

import { IUser } from "interfaces";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  
  const t = useTranslate();
  useTitle(`${t('users.fields.createUsers')}`)

  const { formProps, saveButtonProps } = useForm<IUser>({
    redirect: "list"
  });

  return (
    <Create saveButtonProps={saveButtonProps} title={t("users.fields.createUsers")}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("users.fields.email")}
          name="email"
          rules={[
            {
              required: true,
              message: `${t("messageError")}`
            },
          ]}
        >
          <Input />
        </Form.Item> 
        <Form.Item
          label={t("users.fields.password")}
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: `${t("messageError")}`
            },
          ]}
        >
          <Input type='password' />
        </Form.Item>
        <Form.Item
          label={t("users.fields.repeatePassword")}
          name="repeatePassword"
          hasFeedback
          rules={[
            {
              required: true,
              message: `${t("messageError")}`
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
  
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            })
          ]}
        >
          <Input type='password' />
        </Form.Item>             
      </Form>
    </Create>
  );
};
