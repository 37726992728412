import { SocialList } from "./list";
import { SocialCreate } from "./create";
import { SocialEdit } from "./edit";
import { ProfileOutlined } from '@ant-design/icons';

const SocialCrud = {
    name: "social",
    list:  SocialList,
    create: SocialCreate,
    edit:  SocialEdit,
    icon: <ProfileOutlined />,
    options: {
        label: "Соцсети",
        hasParent: true
    }
}

export default SocialCrud;
