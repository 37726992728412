import { IblockDataType } from "../blocks";
import linkType  from './common/linkType';
import imageType  from './common/imageType';

export default {
    type: "cardsWithHeader",
    formProps: {
        schema: {
            type: "object",
            properties: {                
                header: {
                    type: "string",
                    title: "Заголовок блока"
                },
                text: {
                    type: "string",
                    title: "Описание"
                },
                link: linkType("Кнопка"),
                items: {
                    type: "array",
                    title: "Карточки",
                    items: {
                        type: "object",                        
                        properties: {
                            id: { type: "string" },
                            header: {
                                type: "string",
                                title: "Заголовок"
                            },
                            text: {
                                type: "string",
                                title: "Текст"
                            },
                            photoFile: imageType,
                            link: linkType("Ссылка"),
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            text: {
                "ui:widget": "textarea"
            },
            link: {
                "ui:field": "linkField"
            },
            items: {
                items: {
                    id: {'ui:widget': 'hidden'}, 
                    text: {
                        "ui:widget": "textarea"
                    },
                    photoFile: {
                        "ui:field": "imageField"
                    },
                    link: {
                        "ui:field": "linkField"
                    },
                    linkOth: {
                        "ui:field": "linkField"
                    },                 
                }
            }
        }
    }
} as IblockDataType;