import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  Image,
  Avatar
} from "@pankod/refine-antd";
import { IBank } from "interfaces";
import useTitle from 'utils/useTitle'
import { API_HOST } from "utils/constants";

export const BankList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('bank.title')}`)

  const { tableProps, sorter } = useTable<IBank>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table 
        {...tableProps} 
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale:{ items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
         <Table.Column
          dataIndex="logo"
          key="logo"
          title={t("bank.fields.logo")}
          render={(value) => <Avatar shape="square" size={64} src={<Image preview={false} src={`${value}`} />} />}         
        />     
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("bank.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />  

        <Table.Column<IBank>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
