import { IblockDataType } from "../blocks";
import imageType  from './common/imageType';

export default {
    type: "newsGallery",
    formProps: {
        schema: {
            type: "object",
            properties: {
                items: {
                    type: "array",
                    title: "Изображения",
                    items: {
                        type: "object",                        
                        properties: {
                            photoFile: imageType,
                        }
                    }
                }                         
            }
        },
        uiSchema: {
            items: {
                items: {
                    photoFile: {
                        "ui:field": "imageField"
                    }
                }
            }
        }
    }
} as IblockDataType;
