import { useState } from "react";
import {
  useTranslate,
  useModal,
  useUpdate 
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  useModalForm,
  Modal,
  TagField,
  Typography
} from "@pankod/refine-antd";
import ImportForm from './form';
import SelectTable from './table';

import { ImportOutlined } from '@ant-design/icons';

const RESOURSE = 'import';

const ProjectimportList: React.FC<any> = ({ projectId }: any) => {
  const t = useTranslate();
  const [xlsData, setXlsData] = useState<any>(null);
  const [selectedFields, setSelectedFields] = useState<any>({});

  const tableChoiseProps = useModal();
  const { mutate: importLands } = useUpdate<any>();

  const { tableProps, sorter } = useTable<any>({
    resource: RESOURSE,
    permanentFilter: [
      {
          field: "projectId",
          operator: "eq",
          value: projectId,
      },
   ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  const onMutationSuccess = (data : any) => {
    tableChoiseProps?.show();
    console.log(data.data);
    setXlsData(data.data);
  }

  // Create Modal
  const {
      modalProps: createModalProps,
      formProps: createFormProps,
      show: createModalShow
  } = useModalForm<any>({
      resource: RESOURSE,
      action: "create",
      redirect: false,
      onMutationSuccess: onMutationSuccess,
      warnWhenUnsavedChanges: true,
  });

  const onSelectField = (value: any, cell: any) => {
      const keyToRemove = Object.keys(selectedFields).find((key: any) => (selectedFields[key] === value)) || "";
      if(keyToRemove) {
        delete selectedFields[keyToRemove as any];
      }

      if(!value) {
        delete selectedFields[cell];
      } else {
        selectedFields[cell] = value;
      }
      
      setSelectedFields(selectedFields);      
  }

  const startUpdate = () => { 
    console.log("@selectedFields", selectedFields);   
    importLands({
      id: xlsData.id|| 0,     
      resource: `import`,
      values: selectedFields,
      successNotification: { message: t(`import.success`), type: "success"},
      errorNotification: { message: t(`import.error`), type: "error"},
    });
    tableChoiseProps?.close();    
  }
      
  return (
    <>
      <List 
        title={t("import.list")}
        createButtonProps={{
          icon: (<ImportOutlined />),
          children: t("import.add"),     
          onClick: () => {         
              createModalShow();
          },
        }}         
      >
        <Table<any> 
          {...tableProps}      
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            locale:{ items_per_page: '' }
          }}
        >
          <Table.Column
            dataIndex="id"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />

          <Table.Column
            dataIndex="date"
            key="date"
            title={t("import.fields.date")}         
          />

          <Table.Column
            dataIndex="lines"
            key="lines"
            title={t("import.fields.lines")}         
          />

          <Table.Column
            dataIndex="added"
            key="added"
            title={t("import.fields.added")}         
          />

          <Table.Column
            dataIndex="updated"
            key="updated"
            title={t("import.fields.updated")}         
          />
      
          <Table.Column
            dataIndex="status"
            key="status"
            title={t("import.fields.status")}
            render={(value) => <TagField color={(value?.value === 'done') ? 'green': ((value?.value === 'error') ? 'red' : 'geekblue')} value={value?.name} />}
        
          /> 

          <Table.Column
            dataIndex="file"
            key="file"
            title={t("import.fields.file")}
            render={(value) => value?.url && <Typography.Link target={'_blank'} href={value.url}>{value.name}</Typography.Link>}       
          />

        </Table>
      </List>
      <Modal {...createModalProps} title={t('import.createTitle')} okText={"Продолжить"} >
        <ImportForm fprops={createFormProps} projectId={projectId} />
      </Modal>
    
      <Modal 
        style={{minWidth: "100%"}} 
        {...tableChoiseProps} 
        onCancel={tableChoiseProps.close} 
        onOk={startUpdate} 
        cancelText="Отменить"
        okText={"Обновить"} 
        title={"Выберите колонки"}
      
      >
        {xlsData?.data && <SelectTable onSelectField={onSelectField} data={xlsData.data} />}
      </Modal>
    </>
  );
};

export default ProjectimportList;
