import { useState } from 'react';
import { useTranslate } from "@pankod/refine-core";
import {
  Select,
  Table
} from "@pankod/refine-antd";

const allOptions = [ 
    { 
        label: "",
        value: null
    },
    { 
        label: "номер участка",
        value: "number"
    },
    { 
        label: "площадь",
        value: "area"
    },
    { 
        label: "стоимость",
        value: "price"
    },
    { 
        label: "стоимость со скидкой",
        value: "discountPrice"
    },
    { 
        label: "статуc",
        value: "status",   
        
    }
];

const HeaderRow = ({name, options, onChange}: any) => {    
    return (
    <div key={name} style={{textAlign: "center"}}>
        <div>
            <Select onChange={(v: any) => onChange(v, name)} style={{ minWidth: "150px"}} size="small" options={options}/>
        </div>
        <div>{name}</div>
    </div>
    );
}

const SelectTable: React.FC<any> = ({ data, onSelectField }: any) => {   
     const firstLine = data[0] || {};  


    const onChange = (value: any, e: any) => {
        onSelectField(value, e);
        console.log(value, e);  
    };

    const columns = Object.keys(firstLine).filter((e: any)=>(e!=="key")).map((e: any) => {        
        return {
            title: <HeaderRow name={e} options={allOptions} onChange={onChange} />,
            dataIndex: e,        
            key: e,
        }
    });
    
    const dataSource = data.map((e: any, i: number) => { 
        e.key = i;
        return e;
    });

    return(
        <Table
            size="small"
            pagination={false} 
            dataSource={dataSource} 
            columns={columns} />        
    )
}

export default SelectTable;