import { UserOutlined } from '@ant-design/icons';
import { UsersList } from "./list";
import { UserCreate } from "./create";
import { UsersEdit } from "./edit";

const UsersCrud = {
    name: "users",
    list: UsersList,
    create: UserCreate,
    edit: UsersEdit,
    icon: <UserOutlined />,
    options: {        
        label: "Пользователи"
    }
}

export default UsersCrud;

