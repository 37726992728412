import {
  useTranslate,
  IResourceComponentsProps
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  useSelect,
  getDefaultSortOrder,
  Space, 
  EditButton,
  DeleteButton,
  TagField,
  Select,
  FilterDropdown, 
  FilterDropdownProps,
  Typography
} from "@pankod/refine-antd";

import { IDocs } from "interfaces";
import useTitle from 'utils/useTitle';

export const DocsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  useTitle(`${t('docs.listTitle')}`);

  const { tableProps, sorter } = useTable<IDocs>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ]
  });

  const { selectProps: selectProjectProps } = useSelect({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",               
    sort: [
        {
            field: "name",
            order: "asc",
        },
    ],
  });
  
  return (
    <List>
      <Table
        {...tableProps}
        showSorterTooltip={false}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          locale: { items_per_page: '' }
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="header"
          key="header"
          title={t("docs.fields.header")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("header", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="uploadedFile"
          key="uploadedFile"
          render={(value) => <Typography.Link target={'_blank'} href={value.url}>{value.name}</Typography.Link>}
          title={t("docs.fields.file")}    
        />
        <Table.Column
          dataIndex="manager"
          key="manager"
          title={t("docs.fields.manager")}       
          defaultSortOrder={getDefaultSortOrder("manager", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="date"
          key="date"
          title={t("docs.fields.date")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
         <Table.Column
          dataIndex="project"
          key="project"
          title={t("docs.fields.project")}
          render={(value) => {       
            return (<TagField value={value?.name} />)
          }}
         
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}  >
                <Select {...selectProjectProps} 
                    style={{ minWidth: 200 }}
                    placeholder={t("docs.fields.project")} />
            </FilterDropdown>
        )}
        />

        <Table.Column<IDocs>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
